import React, { createContext, useContext, useEffect, useState } from "react";
import { getSessionSelectedLocation, setSessionSelectedLocation } from "../helper/session";
import { useLocales } from "../locales";

const AppSettingsContext = createContext({
  location: getSessionSelectedLocation(),
  setAppLocation: () => {
  },
  IS_BH: false
});

export const AppSettingsProvider = ({ children }) => {
  const [location, setLocation] = useState(getSessionSelectedLocation());
  const [IS_BH, setIS_BH] = useState(location?.tapURL === "tap.sandbox.suf.poreskaupravars.org");
  const { onChangeLang } = useLocales();
  console.debug("IS_BH", IS_BH);

  useEffect(() => {
    const domain = window.location.hostname;
    if (domain.endsWith(".ba")) {
      setIS_BH(true);
      onChangeLang("ba");
    } else {
      setIS_BH(location?.tapURL === "tap.sandbox.suf.poreskaupravars.org");
    }
  }, []);

  const setAppLocation = (location) => {
    setSessionSelectedLocation(location);
    setLocation(location);
  };

  return (
    <AppSettingsContext.Provider value={{ location, setAppLocation, IS_BH }}>
      {children}
    </AppSettingsContext.Provider>
  );
};


function useAppSettings() {
  return useContext(AppSettingsContext);
}

export { AppSettingsContext, useAppSettings };
