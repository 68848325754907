import packageInfo from "../../../package.json";

const sr = {
  oldFiscalError: "Unesite IBFM ili XXXXXXXX-br. računa",
  oldFiscalHelp:"IBFM ili XXXXXXXX-br. računa",
  onlyDecimals: "Možete unijeti samo {{number}} decimala.",
  customerIdentification: "Identifikacija Kupca",
  customerIdHelper: "Broj LK ili nekog drugog identifikacionog dokumenta",
  otherPaymentType: "Ostalo",
  optionalBuyerInfo: "Opciono polje kupca",
  closeInvoice: "Zatvori račun",
  cancelInvoice: "Poništi račun",
  issueInvoice: "Izdaj račun",
  reportsEFiscal: "Izveštaji eFiskal",
  analyticsEFiscal: "Analitika eFiskal",
  reportEFactures: "Izveštaji eFakture",
  exitConfirmTitle: "Da li ste sigurni da želite da izađete?",
  exitImportProducts: "Svi proizvodi koje ste importovali, biće obrisani.",
  uid: "UID",
  dragFileOrOpen: "Privucite fajl ovde, ili kliknite",
  deleteExistingProducts: "Obriši postojeće proizvode",
  productImport: "Import proizvoda",
  someProductsNotValid: "Neki proizvodi nisu validni!",
  fileNotImported: "Niste importovali fajl!",
  changeProductOnBillName: "Izmeni naziv proizvoda na računu",
  changeProductOnBillPrice: "Unesite novu cenu proizvoda",
  changeProductOnBillDiscount: "Unesite popust za proizvod",
  invoiceTranslate: {
    ba:{
      tax: "Porez",
      name: "Naziv",
      total: "Ukupno",
      tin: "JIB",
      address: "Adresa",
      forPayment: "Za plaćanje",
      price: "Cijena",
      pfrTime: "PFR vrijeme",
      esirTime: "ESIR vrijeme:",
      refTime: "ESIR vrijeme:"
    },
    sr:{
      tax: "Porez",
      name: "Naziv",
      total: "Ukupno",
      tin: "JIB",
      address: "Adresa",
      forPayment: "Za plaćanje",
      price: "Cena",
      pfrTime: "PFR vreme",
      esirTime: "ESIR vreme:",
      refTime: "ESIR vreme:"
    }
  },
  loginHelmet: "Prijava | ESIR",
  aboutAppItemsSR: [
    { title: "Proizvođač:", value: "IT Creator SLTS doo, ul. Mileševska 36A, 11000 Beograd" },
    { title: "Serijski broj:", value: "20220128" },
    { title: "Verzija softvera:", value: packageInfo.version + "." + packageInfo.build },
    { title: "Email za podršku:", value: "podrska@it-creator.rs" },
    { title: "Broj podrške:", value: "+381 11 4444 588" },
    { title: "Veb sajt", value: "www.e-fiskal.rs" },
    {
      title: "Korisni linkovi:", value: [
        {
          title: "Korisničko uputstvo za ESIR WIN Fiskal",
          value: "https://it-creator.rs/download/Korisnicko-uputstvo-za-ESIR-WIN-Fiskal.pdf"
        },
        { title: "Funkcije ESIR WIN Fiskal", value: "https://it-creator.rs/download/ESIR-WIN_Fiskal_funkcije.pdf" },
        { title: "Uputstvo za ESIR magacin", value: "https://it-creator.rs/download/ESIR_Magacin_Uputstvo.pdf" },
        {
          title: "Vodič za izdavanje i vezivanje avansnih računa u ESIR WIN Fiskal",
          value: "https://it-creator.rs/download/" +
            "Korisnicko_uputstvo_za_izdavanje_i_vezivanje_avansnih_računa-ESIR-WIN-Fiskal.pdf"
        }
      ]
    }
  ],
  aboutAppItemsBA: [
    { title: "Proizvođač:", value: "IT Creator Solutions doo, ul. Sime Šolaje 1A, 78000 Banja Luka" },
    { title: "Serijski broj:", value: "20240401" },
    { title: "Verzija softvera:", value: packageInfo.version + "." + packageInfo.build },
    { title: "Email za podršku:", value: "podrska@it-creator.ba" },
    { title: "Broj podrške:", value: "051 981 295" },
    { title: "Veb sajt", value: "https://elektronskafiskalizacija.ba/" },
    {
      title: "Korisni linkovi:", value: [
        {
          title: "Korisničko uputstvo za ESIR WIN Fiskal",
          value: "https://it-creator.ba/download/Korisnicko-uputstvo-za-ESIR-WIN-Fiskal.pdf"
        }
      ]
    }
  ],
  loginOperatorHelmet: "Prijava operatora | ESIR",
  operatorsNotFound: "Operatori nisu pronađeni...",
  compensation: "Kompenzacija",
  dragOrSelectFile: "Prevuci ili izaberi fajl",
  dragFilesHereOrClick: "Prevuci fajlove ovde ili klikni",
  browse: "pregledaj",
  toAddFileMaxSizeIs: "za dodavanje fajla. Maksimalna veličina je",
  maxNumberOfImagesIs: "Maksimalan broj slika je",
  deleteImage: "Obriši sliku",
  areYouSureYouWantToDeleteImage: "Da li ste sigurni da želite da obrišete sliku?",
  discount: "Popust",
  compensations: "Kompenzacije",
  dashboard: "Početna",
  factureNumber: "Broj fakture",
  factureDetails: "Detalji fakture",
  paid: "Plaćeno",
  factures: "Fakture",
  facturePreview: "Pregled fakture",
  allFactures: "Sve fakture",
  allInvoices: "Svi Računi",
  withCompensation: "Sa kompenzacijom",
  warehouse: "Magacin",
  byCompensation: "Kompenzacija",
  unpaid: "Neplaćeno",
  overdue: "Van roka",
  factureNotFound: "Faktura nije pronađena, molimo pokušajte ponovo!",
  total: "Ukupno",
  addCompensation: "Dodaj naknadu",
  remainingToPay: "Preostalo za plaćanje",
  invoiceNumber: "Broj računa",
  editExpenses: "Uredi troškove",
  expense: "Trošak",
  save: "Sačuvaj",
  totalPriceExcludingVAT: "Ukupna cena bez PDV-a",
  totalPriceIncludingVAT: "Ukupna cena sa PDV-om",
  discountPerProduct: "Popust (Po proizvodu)",
  unitPriceExcludingVAT: "Jedinična cena bez PDV-a",
  unitPriceIncludingVAT: "Jedinična cena sa PDV-om",
  priceIsPurchase: "Cena je nabavna",
  rate: "Stopa",
  discounts: "Popusti",
  listExpenses: "Lista troškova",
  edit: "Izmeni",
  clear: "Očisti",
  close: "Zatvori",
  newUser: "Novi korisnik",
  active: "Aktivan",
  costLower: "trošak",
  activateLower: "aktiviraj",
  cardNumber: "Broj kartice",
  discountUsers: "Korisnici popusta",
  listOfDiscounts: "Lista popusta",
  deactivateLower: "deaktiviraj",
  areYouSureYouWantTo: "Da li ste sigurni da želite",
  expenseModification: "Izmena troška",
  activate: "Aktiviraj",
  totalAmount: "Ukupan iznos",
  referenceNumber: "Referentni broj",
  statusModification: "Izmena statusa",
  deactivate: "Deaktiviraj",
  deactivated: "Deaktiviran",
  copy: "Kopija",
  details: "Detalji",
  training: "Obuka",
  sale: "Prodaja",
  proforma: "Predračun",
  profitExclVAT: "Profit bez PDV-a",
  profitInclVAT: "Profit sa PDV-om",
  linkedAdvanceInvoices: "Povezani avansni računi",
  advance: "Avans",
  cancellation: "Otkazivanje",
  counter: "Brojač",
  invoice: "Račun",
  receptionDate: "Datum prijema",
  issuingTime: "Vreme izdavanja",
  productOrServiceName: "Naziv proizvoda ili usluge",
  issuanceDate: "Datum izdavanja",
  transactionType: "Tip transakcije",
  invoiceType: "Tip računa",
  refund: "Refundacija",
  cin: "MB",
  quotations: "Predračuni",
  traffic: "Promet",
  stockList: "Lager lista",
  invoices: "Računi",
  entrance: "Ulaz",
  paymentDate: "Datum plaćanja",
  paymentDateTime: "Datum i vreme plaćanja",
  purchasePriceWithoutVAT: "Nabavna cena bez PDV-a",
  purchasePriceWithVAT: "Nabavna cena sa PDV-om",
  purchaseValueWithVAT: "Nabavna vrednost sa PDV-om",
  purchaseValueWithoutVAT: "Nabavna vrednost bez PDV-a",
  sellingPriceWithoutVAT: "Prodajna cena bez PDV-a",
  sellingPriceWithVAT: "Prodajna cena sa PDV-om",
  sellingValueWithoutVAT: "Prodajna vrednost bez PDV-a",
  sellingValueWithVAT: "Prodajna vrednost sa PDV-om",
  purchaseValueOfOutputWithoutVAT: "Vrednost izlaza bez PDV-a",
  purchaseValueOfOutputWithVAT: "Vrednost izlaza sa PDV-om",
  outputTaxRate: "Stopa izlaznog poreza",
  lpfr: "L-PFR",
  vpfr: "V-PFR",
  addNew: "Dodaj novi",
  contactEmails: "Kontakt emailovi",
  addOrChangeContactEmail: "Dodaj/Izmeni kontakt email",
  developerMode: "Režim programera",
  currentAccount: "Tekući račun",
  virtualKeyboard: "Virtuelna tastatura",
  invoiceSettings: "Podešavanja računa",
  trainingModSection: "Sekcija obuka moda",
  rawMaterials: "Sirovine",
  inputTaxRate: "Stopa ulaznog poreza",
  selectTaxRate: "Izaberite stopu poreza!",
  unitPrice: "Jedinična cena",
  lastPurchasePrice: "Poslednja nabavna cena",
  currentStock: "Trenutne zalihe",
  unitPriceCalculator: "Kalkulator jedinične cene",
  group: "Grupa",
  preview: "Pregled",
  cancel: "Otkaži",
  delete: "Obriši",
  action: "Akcija",
  areYouSureYouWantToDelete: "Da li ste sigurni da želite da obrišete?",
  payments: "Plaćanja",
  taxCategory: "Kategorija poreza",
  taxRate: "Stopa poreza",
  aboutApp: "O aplikaciji",
  remove: "Ukloni",
  addPaymentMethod: "Dodaj način plaćanja",
  addPayment: "Dodaj plaćanje",
  paymentMethod: "Način plaćanja",
  totalAmountForPayment: "Ukupan iznos za plaćanje",
  removeAll: "Ukloni sve",
  append: "Dodaj",
  totalPaid: "Ukupno plaćeno",
  toPay: "Za plaćanje",
  turnedOff: "Isključeno",
  inStock: "Na stanju",
  uponExpiration: "Na isteku",
  notAvailable: "Nedostupno",
  notDefined: "Nije definisano",
  productList: "Lista proizvoda",
  products: "Proizvodi",
  areYouSureToDelete: "Da li ste sigurni da želite da obrišete ",
  productsQuestion: "proizvod(a)?",
  code: "Interna šifra",
  show: "Prikaži",
  unit: "Jedinica",
  category: "Kategorija",
  state: "Stanje",
  product: "Proizvod",
  posted: "Proknjiženo",
  supplier: "Dobavljač",
  paidAmount: "Plaćeni iznos",
  receiptDate: "Datum prijema",
  valueDate: "Datum valute",
  printing: "Štampanje",
  base: "Osnova",
  sellingPrice: "Prodajna cena",
  printTablePrompt: "Da li želite da odštampate celu ili filtriranu tabelu?",
  savedInvoices: "Sačuvani računi",
  printingError: "Greška pri štampanju. Molimo pokušajte ponovo.",
  errorDeletingInvoice: "Greška pri brisanju računa",
  invoiceDeleted: "Faktura obrisana",
  errorLoadingSavedInvoices: "Greška pri učitavanju sačuvanih faktura",
  itemNumber: "Broj stavki",
  finalInvoice: "Konačni račun",
  quantity: "Količina",
  operator: "Operator",
  role: "Uloga",
  creationDate: "Datum kreiranja",
  fiscalize: "Fiskalizuj",
  discountUserModification: "Modifikacija korisnika sa popustom",
  actions: "Akcije",
  newDiscountUser: "Novi korisnik sa popustom",
  errorWhileDeleting: "Greška pri brisanju",
  invoiceNotFound: "Račun nije pronađen",
  discountUser: "Korisnik sa popustom",
  creatingDiscountUser: "Kreiranje korisnika sa popustom",
  username: "Korisničko ime",
  amount: "Iznos",
  issuanceTime: "Vreme izdavanja",
  firstNameLastName: "Ime i prezime",
  enterCardNumbers: "Unesite brojeve kartica (pritisnite Enter nakon unosa)",
  status: "Status",
  unableToDeleteDiscount: "Nije moguće izbrisati ovaj popust.",
  phone: "Telefon",
  numberOfProducts: "Broj proizvoda",
  contactPerson: "Kontakt osoba",
  creatingDiscount: "Kreiranje popusta",
  priceWithDiscount: "Cena sa popustom",
  productOrService: "Proizvod/usluga",
  unitOfMeasure: "Jedinica mere",
  allowedFormat: "Dozvoljeni format",
  dateFrom: "Datum od",
  customerType: "Tip kupca",
  invoiceCancellation: "Refundacija",
  enterIdentificationNumber: "Morate uneti identifikacioni broj!",
  loading: "Učitavanje",
  submit: "Potvrdi",
  final: "Konačni",
  saved: "Sačuvano",
  dpu: "DPU",
  warehouseManagement: "Upravljanje magacinom",
  representations: "Reprezentacija",
  calculations: "Kalkulacije",
  nonFeeTransactions: "Promet bez naknade",
  netQuantity: "Neto količina",
  oldFiscalization: "Stara fiskalizacija",
  selectOperator: "Izaberite operatera",
  creatingIngredient: "Kreiranje sastojka",
  supplierName: "Ime dobavljača",
  dateTo: "Datum do",
  logout: "Odjava",
  analytics: "Analitika",
  salesRecords: "Evidencija prometa",
  analyticsAndReports: "Analitika i izveštaji",
  ean: "Barkod",
  label: "Oznaka",
  initial: "Početno",
  writeOff: "Otpis",
  orderNumber: "Broj narudžbine",
  deletionTime: "Vreme brisanja",
  changePasswordRequest: "Zahtev za promenu lozinke",
  description: "Opis",
  enterFinalInvoiceDetails: "Unesite detalje konačnog računa",
  lastAdvanceInvoiceNumber: "Poslednji broj avansnog računa",
  createInvoiceOldFiscalization: "Kreiranje računa putem stare fiskalizacije",
  invoiceCreation: "Kreiranje računa",
  invoiceNumberBuyerVat: "Broj računa \\ ID kupca...",
  maximumSizeUpTo: "maksimalna veličina do",
  productsServices: "Proizvodi/Usluge",
  purchasePrice: "Nabavna cena",
  enterOrChangeLpfr: "Unesite ili promenite LPFR",
  taxPreview: "Pregled poreza",
  trainingMode: "Režim obuke",
  turnOnOrOffTrainingMode: "Uključite ili isključite režim obuke",
  activeTaxRates: "Aktivne poreske stope",
  selectRate: "Izaberite stopu",
  saveImage: "Sačuvaj sliku",
  invoiceAlreadyExists: "Račun već postoji pod tim brojem",
  totalSumMismatch: "Ukupna suma proizvoda i načina plaćanja se ne podudara!",
  enterAtLeastOnePaymentMethod: "Morate uneti barem jedan način plaćanja",
  enterAtLeastOneProduct: "Morate uneti barem jedan proizvod",
  operatorModification: "Izmena operatera",
  supplierSuccessfullyDeleted: "Dobavljač uspešno obrisan",
  errorWhileLoadingData: "Greška prilikom učitavanja podataka",
  noData: "Nema podataka",
  choosePaymentMethods: "Izaberite načine plaćanja",
  exportImport: "Izvoz/Uvoz proizvoda",
  customerCard: "Kartica kupca",
  chooseLocation: "Izaberite lokaciju",
  itemsOnBill: "Stavke na računu",
  changeQuantity: "Promeni količinu",
  productName: "Naziv proizvoda",
  saveInDatabase: "Sačuvaj u bazi podataka",
  changeProductOnBill: "Promeni proizvod na računu",
  enter: "Unesi",
  trafficSale: "Promet prodaja",
  closeDialog: "Zatvori dijalog",
  clearAllCategories: "Izbriši sve kategorije",
  productNotFound: "Proizvod nije pronađen",
  dpuList: "Lista DPU",
  dpuForDate: "DPU za datum",
  serialNumber: "Serijski broj",
  dishAndBeverageName: "Naziv jela i pića za konzumaciju na licu mesta",
  transferredQuantity: "Preneta količina",
  procuredQuantity: "Nabavljena količina",
  endingStock: "Završno stanje",
  consumedQuantityDuringDay: "Količina potrošena tokom dana (7-8)",
  unitPriceWithVAT: "Jedinična cena sa PDV-om",
  servicesRevenue: "Prihod od usluga (9x10)",
  totalRevenueFromDishesAndBeverages: "Ukupni prihod od jela i pića za konzumaciju na licu mesta (6x10)",
  beveragesRevenue: "Prihod od pića",
  dishesRevenue: "Prihod od jela",
  difference: "Razlika",
  selectSupplier: "Izaberite dobavljača",
  inventory: "Popis",
  stockListForDate: "Lista zaliha za datum",
  cardOverview: "Pregled kartice",
  expected: "Očekivano",
  measured: "Izmereno",
  listOfCustomers: "Lista kupaca",
  averagePurchasePrice: "Prosečna nabavna cena",
  totalQuantity: "Ukupna količina",
  suppliers: "Dobavljači",
  bookIt: "Proknjiži",
  unBook: "Ukloni proknjižavanje",
  productCard: "Kartica proizvoda",
  id: "ID",
  split: "Podeli",
  factureWithThisNumberAlreadyExist: "Faktura sa ovim brojem već postoji",
  moreThanRemainingPayment: "Uneli ste više nego što je preostali iznos za plaćanje",
  supplierHasActiveFactures: "Dobavljač ima aktivne fakture. Brisanje nije moguće!",
  openAdvances: "Otvoreni avansni",
  selectAtLeastOneProduct: "Izaberite bar 1 proizvod",
  selectDateAndEnterAmount: "Izaberite datum i unesite iznos po stopi",
  paymentTotalMustMatch: "Ukupan iznos plaćanja mora se podudarati sa ukupnim iznosom",
  choosePaymentMethod: "Izaberite način plaćanja",
  youCanOnlyEnterNumbers: "Mozete uneti samo brojeve",
  atLeast7Characters: "Mora imati najmanje 7 karaktera",
  exportError: "Greška pri izvozu. Molimo pokušajte ponovo.",
  fieldIsRequired: "Polje je obavezno",
  successfullyModifiedIngredient: "Sastojak uspešno izmenjen",
  successfullyCreatedIngredient: "Sastojak uspešno kreiran",
  errorCreatingIngredient: "Greška pri kreiranju sastojka",
  errorModifyingIngredient: "Greška pri izmeni sastojka",
  print: "Štampaj",
  addingProductService: "Dodavanje proizvoda/usluge",
  factureEdit: "Izmena fakture",
  purchasePriceExclVAT: "Nabavna cena bez PDV-a",
  companyRegistrationNumber: "Matični broj",
  jbkjs: "jbkjs",
  accountant: "Knjigovođa",
  item: "Proizvod",
  settings: "Podešavanja",
  editIngredient: "Izmena sastojka",
  accountSettings: "Podešavanja naloga",
  myInvoices: "Moji računi",
  companyName: "Naziv firme",
  filteredTable: "Filtrirana tabela",
  export: "Izvoz",
  download: "Preuzmi",
  pibNotFoundInAPR: "PIB nije pronađen u APR-u.",
  exportTablePrompt: "Da li želite da izvezete celu ili filtriranu tabelu?",
  supplierWithEnteredPIBExists: "Dobavljač sa unetim PIB-om već postoji!",
  supplierSuccessfullyModified: "Dobavljač uspešno izmenjen",
  supplierSuccessfullyAdded: "Dobavljač uspešno dodat",
  enterPIBToFillInData: "Unesite PIB da biste popunili podatke iz APR-a",
  addAtLeastOneProduct: "Dodajte barem jedan proizvod",
  minimumQuantityIs1: "Minimalna količina je 1",
  invalidDate: "Nevažeći datum",
  invalidDateFrom: "Nevažeći datum od",
  invalidDateTo: "Nevažeći datum do",
  expenses: "Troškovi",
  totalBase: "Ukupno osnov",
  rawMaterial: "Sirovina",
  totalRebate: "Ukupan rabat",
  ingredientRatio: "Odnos sastojaka",
  totalVAT: "Ukupan PDV",
  writeOffTime: "Vreme otpisa",
  forPayment: "Za plaćanje",
  normative: "Normativ",
  addExpense: "Dodaj trošak",
  addDiscount: "Dodaj popust",
  addIngredient: "Dodaj sastojak",
  addUser: "Dodaj korisnika",
  createFacture: "Kreiraj fakturu",
  newIngredient: "Novi sastojak",
  ingredients: "Sastojci",
  ingredient: "Sastojak",
  addProduct: "Dodaj proizvod",
  newProduct: "Novi proizvod",
  enterProductFirst: "Prvo morate uneti proizvod",
  enterIngredientFirst: "Prvo morate uneti sastojak",
  enterExpenseFirst: "Prvo morate uneti trošak",
  reportByFilter: "Izveštaj prema filteru",
  change: "Kusur",
  publishing: "Omogući izdavanje",
  select: "Izaberi",
  createdBy: "Kreirao",
  hide: "Sakrij",
  additionalOptions: "Dodatne opcije",
  newExpense: "Novi trošak",
  creatingClientSupplier: "Kreiranje klijenta/dobavljača",
  expenseCreation: "Kreiranje troška",
  selectAccounts: "Izaberi naloge",
  errorOccurred: "Došlo je do greške!",
  clientSupplier: "Klijent/Dobavljač",
  clientsCustomers: "Komitenti",
  reports: "Izveštaji",
  fullScreen: "Puni ekran",
  minimize: "Minimiziraj",
  syncCompleted: "Završena sinhronizacija za izabrani period",
  syncError: "Došlo je do greške prilikom sinhronizacije",
  owe: "Duguje",
  time: "Vreme",
  searchProduct: "Pretraži proizvod",
  searchClientSupplier: "Pretraži klijenta ili dobavljača",
  date: "Datum",
  table: "Sto",
  claims: "Potražuje",
  balance: "Bilans",
  licenseDuration: "Trajanje licence",
  note: "Napomena",
  icons: "Ikone",
  codes: "Interne šifre",
  viewItems: "Pogledaj stavke",
  chooseItemDisplay: "Izaberite željeni prikaz stavke",
  database: "Baza podataka",
  notValidEmail: "nije validna email adresa.",
  writtenOffItems: "Stavke koje su otpisane",
  editSupplier: "Izmeni dobavljača",
  inventoryHistory: "Istorija popisa",
  newClientSupplier: "Novi klijent/dobavljač",
  newEditClientSupplier: "Novi/izmeni klijenta/dobavljača",
  errorLoadingData: "Greška pri učitavanju podataka",
  costReport: "Izveštaj o troškovima",
  ingredientNotFound: "Sastojak nije pronađen, molimo pokušajte ponovo!",
  passwordUsernameUsedByAnotherOperator: "Ova lozinka ili korisničko ime već se koristi od strane drugog operatera.",
  listOfClientsSuppliers: "Lista klijenata/dobavljača",
  clientSupplierModification: "Izmena klijenta/dobavljača",
  selectAtLeastOneAccount: "Izaberite barem 1 nalog",
  enterSearchParameter: "Unesite parametar pretrage",
  errorDuringSearch: "Greška tokom pretrage!",
  unableToRetrieveGroups: "Nije moguće pronaći grupe!",
  unableToRetrieveOperators: "Nije moguće pronaći operatere!",
  totalSalesForThisPeriod: "Ukupna prodaja za ovaj period",
  totalRefundForThisPeriod: "Ukupna refundacija za ovaj period",
  totalEarningsForThisPeriod: "Ukupna zarada za ovaj period",
  annualTurnover: "Godišnji promet",
  totalSoldFoodBeveragesOther: "Promet po načinu plaćanja",
  food: "Hrana",
  remaining: "Preostalo",
  createNew: "Kreiraj",
  list: "Lista",
  itemExample: "Primer stavke",
  notValid: "Nije validno",
  itemName: "Ime stavke",
  issueDate: "Datum izdavanja",
  priceWithVAT: "Cena sa PDV-om",
  dateAndTime: "Datum i vreme",
  selectPaymentMethod: "Izaberite način plaćanja",
  closeOrMergeAdvanceInvoices: "Zatvaranje ili nadovezivanje avansnih računa",
  selectDateAndAmountPerRate: "Izaberite datum i unesite iznos po stopi",
  totalAmountMismatch: "Ukupan iznos po stopi PDV-a ne odgovara ukupnom iznosu načina plaćanja!",
  unableToFindInvoice: "Nije moguće pronaći račun! Kontaktirajte podršku.",
  priceSuccessfullyUpdated: "Cena uspešno ažurirana",
  errorUpdatingPrice: "Greška pri ažuriranju cene!",
  appendingOrIssuingFinal: "Nadoveži ili izdaj konačni račun",
  productViewEdit: "Pregled/izmena proizvoda",
  invoiceOverview: "Pregled računa",
  invalidInvoiceContactSupport: "Faktura nije validna. Molimo kontaktirajte podršku!",
  totalProductAmountCannotBeLess: "Ukupan iznos proizvoda ne može biti manji od plaćenog iznosa!",
  exampleAdvanceInvoiceOldFiscalization: "Primer avansnog računa prethodne fiskalizacije",
  noAccountsAssociatedWithInvoice: "Trenutno nema povezanih naloga sa ovom fakturom",
  noInvoices: "Nema računa",
  newPrice: "Nova cena",
  invoiceList: "Lista računa",
  itemOverviewEdit: "Pregled/izmena proizvoda",
  successfullyCompletedCompensation: "Uspešno ste dodali kompenzaciju",
  successfullyRemovedCompensation: "Uspešno ste uklonili kompenzaciju",
  tax: "Porez",
  taxExample: "Grupa: A Porez: 0%",
  clients: "Klijenti",
  priceEdit: "Izmena cene",
  exit: "Izlaz",
  error: "Greška",
  confirm: "Potvrdi",
  search: "Pretraga",
  tin: "PIB",
  invoiceFrom: "Račun od",
  paymentCurrency: "Valuta plaćanja",
  type: "Tip",
  accountNumber: "Broj računa",
  months: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"],
  beverage: "Piće",
  other: "Ostalo",
  cash: "Gotovina",
  card: "Kartica",
  check: "Ček",
  wireTransfer: "Prenos na račun",
  voucher: "Vaučer",
  mobileMoney: "Mobilni novac",
  cashier: "Kasir",
  country: "Država",
  sales: "Prodaja",
  top: "Top",
  email: "Email",
  address: "Adresa",
  city: "Grad",
  user: "Korisnik",
  number: "Broj",
  totalSales: "Ukupna prodaja",
  totalRefund: "Ukupno povraćaj",
  password: "Šifra",
  addingSupplier: "Dodavanje dobavljača",
  signOut: "Odjavi me",
  deleting: "Brisanje",
  accept: "Prihvati",
  chosen: "Izabrano",
  theme: "Tema",
  new: "Novo",
  old: "Staro",
  youHave: "Imate",
  unreadMessages: "nepročitane poruke",
  MarkAllAsRead: "Označi sve kao pročitano",
  surname: "Prezime",
  notifications: "Obaveštenja",
  showAll: "Prikaži sve",
  jsonFileInterrupted: "Json fajl prekinut",
  importSuccess: "Uspešan import",
  downloadJsonFileErrors: "Preuzmi fajl sa neuspelim json objektima",
  importErrors: "Greške pri importu",
  dragFileHereOrClick: "Prevuci fajl ovde ili klikni",
  invoiceImport: "Uvoz računa",
  chooseLightOrDark: "Izaberi svetlu ili tamnu temu",
  successSaved: "Uspešno sačuvano",
  passwordResetLinkSentSuccessfully: "Link za resetovanje šifre uspešno poslat",
  adjustAppearanceInvoice: "Prilagodi izgled svog računa",
  productDontHaveId: "Proizvod nema ID",
  startProducts: "Početni proizvodi",
  advertisingText: "Reklamni tekst",
  printLogo: "Štampaj logo",
  printTestInvoice: "Štampaj uzorak računa",
  turnOnOrOffVirtualKeyboard: "Uključi ili isključi virtuelnu tastaturu",
  searchProductsServices: "Pretraži proizvode/usluge",
  signIn: "Prijavi se",
  enterFullVATID: "Unesite puni PIB da biste pronašli novog kupca",
  confirmDelete: "Da li ste sigurni da želite da obrišete?",
  operators: "Operateri",
  addOperator: "Dodaj operatera",
  customerList: "Lista kupaca",
  listOperators: "Lista operatera",
  exampleSignInToThePortal: "Primer prijave na portal",
  passwordIsRequired: "Šifra je obavezna!",
  invalidCredentials: "Uneti podaci nisu validni!",
  emailIsRequired: "Email je obavezan!",
  emailFormatValidation: "Email adresa mora biti u validnom formatu!",
  thanLastMonth: " od prošlog meseca",
  topSellingProductsForThisMonth: "Najprodavaniji proizvodi za izabrani period",
  topOperatorsForThisMonth: "Operateri za izabrani period",
  trafficAnalytics: "Analitika",
  errorMessage: "Došlo je do greške",
  listOfOperators: "Lista operatera",
  addCustomers: "Dodaj kupce",
  newOperator: "Novi operater",
  customerDatabase: "Kupci",
  inactive: "neaktivan",
  creatingOperator: "Kreiranje operatera",
  basic: "Osnova",
  name: "Naziv",
  all: "Svi",
  add: "Dodaj",
  price: "Cena",
  profile: "Profil",
  advertisements: "Reklame",
  categories: "Kategorije",
  bill: "Račun",
  ppProducts: "proizvode",
  noteIsRequired: "Napomena je obavezna!",
  printA4: "A4 format",
  imageMustBeIn169Format: "Slika mora biti u 16:9 formatu",
  noSearchResult: "Nema rezultata pretrage",
  noSearchResultFor: "Nema pronađenih reuzultata pretrage za",
  pleaseCheckInput: "Molimo Vas proverite da li je unos ispravan",
  enterKeyword: "Unesite ključne reči",
  issueAgin: "Izdaj ponovo",
  chooseItems: "Izaberite proizvode",
  issue: "Izdaj",
  forbidden: "Zabranjen pristup",
  noAcces: " Nemate dozvolu za ovu stranicu",
  cinJmbg: "MB/JMBG",
  choose: "Izaberite",
  searchPages: "Pretražite stranicu",
  esirNumber: "ESIR broj",
  issuingPlace: "Mesto izdavanja",
  cannotLoadLocations: "Nije moguće očitati lokacije",
  cannotLoadTaxRates: "Nije moguće očitati poreske stope",
  cannotLoadSettings: "Nije moguće očitati podešavanja",
  cannotLoadBankAccount: "Nije moguće očitati tekući račun",
  cannotLoadUserImage: "Nije moguće očitati logo",
  cannotLoadAds: "Nije moguće očitati reklamni tekst",
  invoiceSyncError: "Nije moguće sinhronizovati račune",
  locationSuccessAdded: "Uspešno dodeljena lokacija",
  enterNewName: "Unesite novi naziv",
  enterNewPrice: "Unesite novu cenu",
  a4Preview: "Pregled a4 formata",
  a4Print: "Štampanje a4 formata",
  company: "Kompanija",
  location: "Lokacija",
  district: "Opština",
  productOrProducts: "proizvod/a",
  loadingData: "Očitavanje podataka",
  discountEdit: "Izmena popusta",
  deleted: "Obrisan",
  cannotIssueThisInvoice: "Ne možete ponvo da izdate ovaj račun",
  itemsCodeDescription: "(KOLIČINA * ŠIFRA PROIZVODA)",
  newProductService: "Nov proizvod/usluga",
  alreadyHaveCode: "Već postoji proizvod/usluga sa ovim internom šifrom",
  addNormative: "Dodaj normativ",
  sold: "Prodato",
  refunded: "Refundirano",
  yearSell: "Godišnja prodaja",
  contact: "Kontakt",
  jbkjc: "JBKJS",
  eFactures: "eFakture",
  exitInvoices: "Izlazne fakture",
  newExitInvoice: "Nova izlazna faktura",
  incomingInvoices: "Ulazne fakture",
  individualRecords: "Pojedinačna evidencija",
  newIndividualRecord: "Nova pojedinačna evidencija",
  summaryRecords: "Zbirne evidencije",
  newSummaryRecord: "Nova zbirna evidencija",
  customerAlreadyExist: "Već postoji kupac sa ovim PIB/MB brojem",
  a4Email: "Email za prikaz na a4 formatu",
  companyInfo: "Detalji o kompaniji",
  basicInfo: "Podešavanja naloga",
  saveBasicInfo: "Sačuvaj podešavanja naloga",
  saveCompanyInfo: "Sačuvaj podešavanja o kompaniji",
  yes: "Da",
  no: "Ne",
  overview: "Pregled",
  allF: "Sve",
  totalTaxForThisPeriod: "Ukupan pdv za ovaj period",
  totalBasicForThisPeriod: "Ukupna osnovica ovaj period",
  editProductService: "Izmena proizvoda/usluge",
  chooseProductService: "Izaberite proizvod/uslugu",
  reportFor: "Izveštaj za",
  month: "Mesec",
  year: "Godina",
  itemReport: "Izveštaj o proizvodu",
  downloadExcel: "Preuzmi eksel",
  downloadImage: "Preuzmi sliku",
  dateFromNotValid: "Datum od nije validan",
  dateToNotValid: "Datum do nije validan",
  dateToBeforeFrom: "Datum od je veći od datuma do",
  ingName: "Naziv sastojka",
  taxVat: "Poreska stopa",
  dateOfIssue: "Datum izdavanja",
  kitchen: "Kuhinja",
  bar: "Šank",
  bartender: "Šanker",
  driver: " Vozač",
  parking: " Parking",
  adminTourist: "Admin Turista",
  operatorTourist: "Operater Turista",
  waiter: "Konobar",
  chef: "Kuvar",
  admin: "Administrator",
  manager: "Menadžer",
  services: "Usluge",
  eFactureAdmin: "eFakture Admin",
  eFactureExitDocuments: "eFakture izlazna dokumenta",
  eFactureEnterDocuments: "eFakture ulazna dokumenta",
  eFactureUser: "eFakture Korisnik",
  eFactureRevisor: "eFakture Revizor",
  goods: "Roba",
  units: ["kom", "kg", "km", "g", "l", "tona", "m2", "m3", "minut", "h", "dan", "mesec", "godina", "par", "sum", "ostalo"],
  licenseExpiredMessage: "Vaša licenca je nekativna zbog neplaćanja.",
  callTenantMessage: "Molimo Vas da se obratite Vašem distributeru.",
  mustBeGreaterThen: "Mora biti veće od 0",
  oneItemRequired: "Morate izabrati barem jedan proizvod",
  productIng: "Proizvod/Sastojak",
  marza: "Marža",
  ruc: "RUC",
  logoutError: "Greška prilikom odjave",
  createWriteOff: "Kreiranje otpisa",
  maxSessionMessage: "Dostignut je maksimalan broj aktivnih sesija.",
  file: "Fajl",
  alreadyExist: "već postoji!",
  csvInvoice: "Import csv računa",
  oneFileRequired: "Morate izabrati barem jedan .csv fajl!",
  priceWithDiscountWithoutTax: "Cena sa popustom bez PDV-a",
  noOnState: "Nema na stanju",
  expiration: "Pri isteku",
  oneOf: "Jedna od",
  fileIsNotValid: "Fajl nije ispravan!",
  validate: "Validiraj",
  profit: "Zarada",
  withTax: "Sa PDV-om",
  withoutTax: "Bez PDV-a",
  periodFrom: "Period od",
  period: "Period",
  filter: "Filter",
  chooseDates: "Izaberite datume",
  chooseMinPeriod: "Izaberite manji period!",
  stateOverview: "Presek stanja",
  yesterdayOverview: "Jučerašnji presek",
  overviewByDate: "Presek po datumu",
  totalInvoiceNumber: "Ukupan broj izdatih računa",
  JID: "JID",
  trafficByLocation: "Promet po lokaciji",
  saleProduct: "Prodati proizvodi/usluge",
  refundProduct: "Refundirani proizvodi/usluge",
  paymentTypes: "Načini plaćanja",
  cashiers: "Kasiri",
  taxByVat: "Iznos poreza po poreskim stopama",
  summary: "Sumiranje",
  taxBalance: "Saldo poreza",
  totalTaxForRefund: "Ukupan porez za refundaciju",
  totalTaxForSale: "Ukupan porez za prodaju",
  totalSaleBalance: "Ukupan saldo prodaje",
  totalRefundBalance: "Ukupan iznos refundacije",
  totalAmountOfSale: "Ukupan iznos prodaje",
  firmName: "Naziv frime",
  zipCode: "Poštanski broj",
  reportForPeriod: "Izveštaj za period",
  to: "do",
  reportDetails: "Detalji izveštaja",
  sellItems: "Prodati Artikli",
  errorWithExport: "Greška prilikom eksporta. Pokušajte ponovo.",
  report: "izveštaja",
  allReport: "Ceo izveštaj",
  allDetails: "Sa svim detaljima",
  partialReport: "Delimičan izveštaj",
  onlyBalance: "Samo sumiranje",
  reportB: "Izveštaj",
  tehnicalSupport: "Tehnička podrška dostupna: ",
  signInTitle: "Prijavi se na WIN Fiskal",
  welcomeText: "Dobrodošli na WIN Fiskal - Fiskalizacija koja vam je neophodna",
  detailedOn: "Detaljnije na: ",
  or: " ili ",
  percentage: "Procentualno",
  inputDiscount: "Unesite popust u ",
  enterDiscount: "Unesite iznos popusta",
  newPriceCannotBeBelovedZero: "Nova cena ne može biti ispod nule!",
  checkOnline: "Proveri online",
  notFound: "Nije pronadjen",
  prepTime: "Priprema",
  qtyForAlarm: "Količina za alarm",
  productType: "Tip proizvoda",
  disableDiscount: "Onemogući popust",
  turnOffProduct: "Isključi proizvod",
  productIsNormative: "Proizvod je normativ",
  productHaveNormative: "Proizvod ima normativ",
  addProductNormativeDesc: "Ako izaberte normativ količina se ne dodaje za proizvod.",
  draft: "Nacrt",
  recorded: "Evidentiran",
  replaced: "Korigovani",
  cancelled: "Otkazani",
  statusChangeDate: "Datum promene statusa",
  success: "Uspešno",
  send: "Pošalji",
  russian: "ruski",
  serbian: "srpski",
  english: "engleski",
  customers: "Kupci",
  referrals: "Referali",
  commingSoon: "Uskoro",
  prevousMonthPrecentage: "nego prošli mesec",
  lastSaleTransactions: "Poslednje transakcije prodaje",
  trafficExitFactures: "Promet izlaznih faktura",
  trafficEnterFactures: "Promet ulaznih faktura",
  permissionDenied: "Dozvola odbijena",
  notHavePermission: "Nemate dozvolu za pristup ovoj stranici",
  back: "Nazad",
  connect: "Nadoveži"
};

export default sr;
