import packageInfo from "../../../package.json";

const de = {
  oldFiscalError: "Unesite IBFM ili XXXXXXXX-br. računa",
  oldFiscalHelp:"IBFM ili XXXXXXXX-br. računa",
  onlyDecimals: "Možete unijeti samo {{number}} decimala.",
  customerIdentification: "Identifikacija Kupca",
  customerIdHelper: "Broj LK ili nekog drugog identifikacionog dokumenta",
  otherPaymentType: "Ostalo",
  optionalBuyerInfo: "Opciono polje kupca",
  closeInvoice: "Zatvori račun",
  cancelInvoice: "Poništi račun",
  issueInvoice: "Izdaj račun",
  reportsEFiscal: "eFiskal Berichte",
  analyticsEFiscal: "eFiskal Analytik",
  reportEFactures: "eFaktur Berichte",
  exitConfirmTitle: "Sind Sie sicher, dass Sie beenden möchten?",
  exitImportProducts: "Alle importierten Produkte werden gelöscht.",
  uid: "UID",
  dragFileOrOpen: "Ziehen Sie die Datei hierhin oder klicken Sie",
  deleteExistingProducts: "Bestehende Produkte löschen",
  productImport: "Produktimport",
  someProductsNotValid: "Einige Produkte sind ungültig!",
  fileNotImported: "Datei nicht importiert!",
  changeProductOnBillName: "Bearbeiten Sie den Produktnamen in Ihrem Konto",
  changeProductOnBillPrice: "Geben Sie den neuen Produktpreis ein",
  changeProductOnBillDiscount: "Geben Sie einen Produktrabatt ein",
  invoiceTranslate: {
    ba:{
      tax: "Porez",
      name: "Naziv",
      total: "Ukupno",
      tin: "JIB",
      address: "Adresa",
      forPayment: "Za plaćanje",
      price: "Cijena",
      pfrTime: "PFR vrijeme",
      esirTime: "ESIR vrijeme:",
      refTime: "ESIR vrijeme:"
    },
    sr:{
      tax: "Porez",
      name: "Naziv",
      total: "Ukupno",
      tin: "JIB",
      address: "Adresa",
      forPayment: "Za plaćanje",
      price: "Cena",
      pfrTime: "PFR vreme",
      esirTime: "ESIR vreme:",
      refTime: "ESIR vreme:"
    }
  },
  loginHelmet: "Anmeldung | ESIR",
  aboutAppItemsSR: [
    { title: "Hersteller:", value: "IT Creator SLTS doo, ul. Krunska 24, 11000 Belgrad" },
    { title: "Seriennummer:", value: "20220128" },
    { title: "Softwareversion:", value: "1.0" },
    { title: "E-Mail für Support:", value: "podrska@it-creator.sr" },
    { title: "Supportnummer:", value: "+381 11 4444 588" },
    { title: "Website", value: "www.esir.rs" },
    {
      title: "Nützliche Links:", value: [
        {
          title: "Benutzerhandbuch für ESIR WIN Fiskal",
          value: "https://it-creator.rs/download/Korisnicko-uputstvo-za-ESIR-WIN-Fiskal.pdf"
        },
        { title: "ESIR WIN Fiskal Funktionen", value: "https://it-creator.rs/download/ESIR-WIN_Fiskal_funkcije.pdf" },
        { title: "ESIR Lager Anleitung", value: "https://it-creator.rs/download/ESIR_Magacin_Uputstvo.pdf" },
        {
          title: "Benutzerhandbuch zum Ausstellen und Binden von ESIR WIN Fiskal-Vorschussrechnungen",
          value: "https://it-creator.rs/download/" +
            "Korisnicko_uputstvo_za_izdavanje_i_vezivanje_avansnih_računa-ESIR-WIN-Fiskal.pdf"
        }
      ]
    }
  ],
  aboutAppItemsBA: [
    { title: "Proizvođač:", value: "IT Creator Solutions doo, ul. Sime Šolaje 1A, 78000 Banja Luka" },
    { title: "Serijski broj:", value: "20240401" },
    { title: "Verzija softvera:", value: packageInfo.version + "." + packageInfo.build },
    { title: "Email za podršku:", value: "podrska@it-creator.ba" },
    { title: "Broj podrške:", value: "051 981 295" },
    { title: "Veb sajt", value: "https://elektronskafiskalizacija.ba/" },
    {
      title: "Korisni linkovi:", value: [
        {
          title: "Korisničko uputstvo za ESIR WIN Fiskal",
          value: "https://it-creator.ba/download/Korisnicko-uputstvo-za-ESIR-WIN-Fiskal.pdf"
        }
      ]
    }
  ],
  loginOperatorHelmet: "Anmeldung Betreiber | ESIR",
  operatorsNotFound: "Betreiber nicht gefunden...",
  compensation: "Kompensation",
  dragOrSelectFile: "Datei ziehen oder auswählen",
  dragFilesHereOrClick: "Dateien hierher ziehen oder klicken",
  browse: "durchsuchen",
  toAddFileMaxSizeIs: "um die Dateigröße hinzuzufügen, beträgt maximal",
  maxNumberOfImagesIs: "Maximale Anzahl von Bildern ist",
  deleteImage: "Bild löschen",
  areYouSureYouWantToDeleteImage: "Möchten Sie das Bild wirklich löschen?",
  discount: "Rabatt",
  compensations: "Kompensationen",
  dashboard: "Dashboard",
  factureNumber: "Rechnungsnummer",
  factureDetails: "Rechnungsdetails",
  paid: "Bezahlt",
  factures: "Rechnungen",
  facturePreview: "Rechnungsvorschau",
  allFactures: "Alle Rechnungen",
  allInvoices: "Alle Rechnungen",
  withCompensation: "Mit Kompensation",
  warehouse: "Lager",
  byCompensation: "Nach Kompensation",
  unpaid: "Unbezahlt",
  overdue: "Überfällig",
  factureNotFound: "Rechnung nicht gefunden, bitte erneut versuchen!",
  total: "Gesamt",
  addCompensation: "Kompensation hinzufügen",
  remainingToPay: "Noch zu zahlen",
  invoiceNumber: "Rechnungsnummer",
  editExpenses: "Ausgaben bearbeiten",
  expense: "Ausgabe",
  save: "Speichern",
  totalPriceExcludingVAT: "Gesamtpreis ohne MwSt.",
  totalPriceIncludingVAT: "Gesamtpreis inkl. MwSt.",
  discountPerProduct: "Rabatt (pro Produkt)",
  unitPriceExcludingVAT: "Stückpreis ohne MwSt.",
  unitPriceIncludingVAT: "Stückpreis inkl. MwSt.",
  priceIsPurchase: "Preis ist Einkaufspreis",
  rate: "Rate",
  discounts: "Rabatte",
  listExpenses: "Liste der Ausgaben",
  edit: "Bearbeiten",
  clear: "Löschen",
  close: "Schließen",
  newUser: "Neuer Benutzer",
  active: "Aktiv",
  costLower: "Kosten",
  activateLower: "Aktivieren",
  cardNumber: "Kartennummer",
  discountUsers: "Rabattbenutzer",
  listOfDiscounts: "Liste der Rabatte",
  deactivateLower: "Deaktivieren",
  areYouSureYouWantTo: "Sind Sie sicher, dass Sie möchten",
  expenseModification: "Ausgabemodifikation",
  activate: "Aktivieren",
  totalAmount: "Gesamtbetrag",
  referenceNumber: "Referenznummer",
  statusModification: "Statusänderung",
  deactivate: "Deaktivieren",
  deactivated: "Deaktiviert",
  copy: "Kopieren",
  details: "Details",
  training: "Training",
  sale: "Verkauf",
  proforma: "Proforma",
  profitExclVAT: "Gewinn exkl. MwSt.",
  profitInclVAT: "Gewinn inkl. MwSt.",
  linkedAdvanceInvoices: "Verknüpfte Vorschussrechnungen",
  advance: "Vorschuss",
  cancellation: "Stornierung",
  counter: "Zähler",
  invoice: "Rechnung",
  receptionDate: "Empfangsdatum",
  issuingTime: "Ausgabezeit",
  productOrServiceName: "Produkt- oder Dienstleistungsname",
  issuanceDate: "Ausstellungsdatum",
  transactionType: "Transaktionstyp",
  invoiceType: "Rechnungstyp",
  refund: "Rückerstattung",
  cin: "CIN",
  quotations: "Angebote",
  traffic: "Verkehr",
  stockList: "Bestandsliste",
  invoices: "Rechnungen",
  entrance: "Eingang",
  paymentDate: "Zahlungsdatum",
  paymentDateTime: "Zahlungsdatum und -zeit",
  purchasePriceWithoutVAT: "Einkaufspreis ohne MwSt.",
  purchasePriceWithVAT: "Einkaufspreis inkl. MwSt.",
  purchaseValueWithVAT: "Einkaufswert inkl. MwSt.",
  purchaseValueWithoutVAT: "Einkaufswert ohne MwSt.",
  sellingPriceWithoutVAT: "Verkaufspreis ohne MwSt.",
  sellingPriceWithVAT: "Verkaufspreis inkl. MwSt.",
  sellingValueWithoutVAT: "Verkaufswert ohne MwSt.",
  sellingValueWithVAT: "Verkaufswert inkl. MwSt.",
  purchaseValueOfOutputWithoutVAT: "Einkaufswert der Ausgabe ohne MwSt.",
  purchaseValueOfOutputWithVAT: "Einkaufswert der Ausgabe inkl. MwSt.",
  outputTaxRate: "Ausgangssteuersatz",
  lpfr: "LPFR",
  addNew: "Neu hinzufügen",
  contactEmails: "Kontakt-E-Mails",
  addOrChangeContactEmail: "Kontakt-E-Mail hinzufügen/ändern",
  developerMode: "Entwicklermodus",
  currentAccount: "Aktuelles Konto",
  virtualKeyboard: "Virtuelle Tastatur",
  invoiceSettings: "Rechnungseinstellungen",
  trainingModSection: "Bereich für den Schulungsmodus",
  rawMaterials: "Rohstoffe",
  inputTaxRate: "Eingangssteuersatz",
  selectTaxRate: "Steueratz auswählen!",
  unitPrice: "Stückpreis",
  lastPurchasePrice: "Letzter Einkaufspreis",
  currentStock: "Aktueller Lagerbestand",
  unitPriceCalculator: "Stückpreisrechner",
  group: "Gruppe",
  preview: "Vorschau",
  cancel: "Abbrechen",
  delete: "Löschen",
  action: "Aktion",
  areYouSureYouWantToDelete: "Sind Sie sicher, dass Sie löschen möchten?",
  payments: "Zahlungen",
  taxCategory: "Steuerkategorie",
  taxRate: "Steueratz",
  aboutApp: "Über die App",
  remove: "Entfernen",
  addPaymentMethod: "Zahlungsmethode hinzufügen",
  addPayment: "Zahlung hinzufügen",
  paymentMethod: "Zahlungsmethode",
  totalAmountForPayment: "Gesamtbetrag für Zahlung",
  removeAll: "Alle entfernen",
  append: "Anhängen",
  totalPaid: "Gesamt gezahlt",
  toPay: "Zu zahlen",
  turnedOff: "Ausgeschaltet",
  inStock: "Auf Lager",
  uponExpiration: "Bei Ablauf",
  notAvailable: "Nicht verfügbar",
  notDefined: "Nicht definiert",
  productList: "Produktliste",
  products: "Produkte",
  areYouSureToDelete: "Sind Sie sicher, dass Sie löschen möchten ",
  productsQuestion: "Produkt(e)?",
  code: "Code",
  show: "Anzeigen",
  unit: "Einheit",
  category: "Kategorie",
  state: "Status",
  product: "Produkt",
  posted: "Veröffentlicht",
  supplier: "Lieferant",
  paidAmount: "Bezahlter Betrag",
  receiptDate: "Belegdatum",
  valueDate: "Wertstellungsdatum",
  printing: "Drucken",
  base: "Basis",
  sellingPrice: "Verkaufspreis",
  printTablePrompt: "Möchten Sie die gesamte oder gefilterte Tabelle drucken?",
  savedInvoices: "Gespeicherte Rechnungen",
  printingError: "Druckfehler. Bitte versuchen Sie es erneut.",
  errorDeletingInvoice: "Fehler beim Löschen der Rechnung",
  invoiceDeleted: "Rechnung gelöscht",
  errorLoadingSavedInvoices: "Fehler beim Laden der gespeicherten Rechnungen",
  itemNumber: "Artikelnummer",
  finalInvoice: "Endgültige Rechnung",
  quantity: "Menge",
  operator: "Betreiber",
  role: "Rolle",
  creationDate: "Erstellungsdatum",
  fiscalize: "Fiskalisieren",
  discountUserModification: "Rabattbenutzer-Änderung",
  actions: "Aktionen",
  newDiscountUser: "Neuer Rabattbenutzer",
  errorWhileDeleting: "Fehler beim Löschen",
  invoiceNotFound: "Rechnung nicht gefunden",
  discountUser: "Rabattbenutzer",
  creatingDiscountUser: "Rabattbenutzer erstellen",
  username: "Benutzername",
  amount: "Betrag",
  issuanceTime: "Ausstellungszeit",
  firstNameLastName: "Vorname und Nachname",
  enterCardNumbers: "Kartenummern eingeben (nach Eingabe Enter drücken)",
  status: "Status",
  unableToDeleteDiscount: "Es ist nicht möglich, diesen Rabatt zu löschen.",
  phone: "Telefon",
  numberOfProducts: "Anzahl der Produkte",
  contactPerson: "Kontaktperson",
  creatingDiscount: "Rabatt erstellen",
  priceWithDiscount: "Preis mit Rabatt",
  productOrService: "Produkt/Dienstleistung",
  unitOfMeasure: "Maßeinheit",
  allowedFormat: "Erlaubtes Format",
  dateFrom: "Datum von",
  customerType: "Kundentyp",
  invoiceCancellation: "Rechnungsstornierung",
  enterIdentificationNumber: "Sie müssen eine Identifikationsnummer eingeben!",
  loading: "Laden",
  submit: "Senden",
  final: "Endgültig",
  saved: "Gespeichert",
  dpu: "DPU",
  warehouseManagement: "Lagerverwaltung",
  representations: "Darstellungen",
  calculations: "Berechnungen",
  nonFeeTransactions: "Gebührenfreie Transaktionen",
  netQuantity: "Nettomenge",
  oldFiscalization: "Alte Fiskalisierung",
  selectOperator: "Operator auswählen",
  creatingIngredient: "Zutat erstellen",
  supplierName: "Lieferantenname",
  dateTo: "Datum bis",
  logout: "Abmelden",
  analytics: "Analytik",
  salesRecords: "Verkaufsprotokolle",
  analyticsAndReports: "Analytik und Berichte",
  ean: "EAN",
  label: "Etikett",
  initial: "Anfang",
  writeOff: "Abschreibung",
  orderNumber: "Bestellnummer",
  deletionTime: "Löschzeit",
  changePasswordRequest: "Passwortänderungsanfrage",
  description: "Beschreibung",
  enterFinalInvoiceDetails: "Geben Sie die Details der endgültigen Rechnung ein",
  lastAdvanceInvoiceNumber: "Letzte Nummer der Vorausrechnung für eine bestimmte Transaktion",
  createInvoiceOldFiscalization: "Erstellen einer Rechnung unter alter Fiskalisierung",
  invoiceCreation: "Rechnungserstellung",
  invoiceNumberBuyerVat: "Rechnungsnummer \\ Käufer-MwSt.-Nummer...",
  maximumSizeUpTo: "maximale Größe bis zu",
  productsServices: "Produkte/Dienstleistungen",
  purchasePrice: "Einkaufspreis",
  enterOrChangeLpfr: "LPFR eingeben oder ändern",
  taxPreview: "Steuervorschau",
  trainingMode: "Schulungsmodus",
  turnOnOrOffTrainingMode: "Schulungsmodus ein- oder ausschalten",
  activeTaxRates: "Aktive Steuersätze",
  selectRate: "Steuersatz auswählen",
  saveImage: "Bild speichern",
  invoiceAlreadyExists: "Rechnung existiert bereits unter dieser Nummer",
  totalSumMismatch: "Die Gesamtsumme der Produkte und Zahlungsmethoden stimmt nicht überein!",
  enterAtLeastOnePaymentMethod: "Sie müssen mindestens eine Zahlungsmethode eingeben",
  enterAtLeastOneProduct: "Sie müssen mindestens ein Produkt eingeben",
  operatorModification: "Operator-Änderung",
  supplierSuccessfullyDeleted: "Lieferant erfolgreich gelöscht",
  errorWhileLoadingData: "Fehler beim Laden der Daten",
  noData: "Keine Daten",
  choosePaymentMethods: "Zahlungsmethoden auswählen",
  exportImport: "Export/Import",
  customerCard: "Kundenkarte",
  chooseLocation: "Standort auswählen",
  itemsOnBill: "Artikel auf Rechnung",
  changeQuantity: "Menge ändern",
  productName: "Produktname",
  saveInDatabase: "In Datenbank speichern",
  changeProductOnBill: "Produkt auf Rechnung ändern",
  enter: "Eingeben",
  trafficSale: "Verkauf im Verkehr",
  closeDialog: "Dialog schließen",
  clearAllCategories: "Alle Kategorien löschen",
  productNotFound: "Produkt nicht gefunden",
  dpuList: "DPU-Liste",
  dpuForDate: "DPU für Datum",
  serialNumber: "Seriennummer",
  dishAndBeverageName: "Speisen- und Getränkenamen für den Verzehr vor Ort",
  transferredQuantity: "Übertragene Menge",
  procuredQuantity: "Beschaffte Menge",
  endingStock: "Endbestand",
  consumedQuantityDuringDay: "Verbrauchte Menge während des Tages (7-8)",
  unitPriceWithVAT: "Einheitspreis mit MwSt.",
  servicesRevenue: "Einnahmen aus Dienstleistungen (9x10)",
  totalRevenueFromDishesAndBeverages: "Gesamteinnahmen aus Speisen und Getränken für den Verzehr vor Ort (6x10)",
  beveragesRevenue: "Einnahmen aus Getränken",
  dishesRevenue: "Einnahmen aus Speisen",
  difference: "Unterschied",
  selectSupplier: "Lieferanten auswählen",
  inventory: "Inventar",
  stockListForDate: "Bestandsliste für Datum",
  cardOverview: "Kartenübersicht",
  expected: "Erwartet",
  measured: "Gemessen",
  listOfCustomers: "Liste der Kunden",
  averagePurchasePrice: "Durchschnittlicher Kaufpreis",
  totalQuantity: "Gesamtmenge",
  suppliers: "Lieferanten",
  bookIt: "Buchen",
  unBook: "Stornieren",
  productCard: "Produktkarte",
  id: "ID",
  split: "Teilen",
  factureWithThisNumberAlreadyExist: "Rechnung mit dieser Nummer existiert bereits",
  moreThanRemainingPayment: "Sie haben einen höheren Betrag als den ausstehenden Zahlungsbetrag eingegeben",
  supplierHasActiveFactures: "Der Lieferant hat aktive Rechnungen. Löschen ist nicht möglich!",
  openAdvances: "Offene Vorschüsse",
  selectAtLeastOneProduct: "Wählen Sie mindestens 1 Produkt aus",
  selectDateAndEnterAmount: "Wählen Sie ein Datum und geben Sie den Betrag pro Satz ein",
  paymentTotalMustMatch: "Die Gesamtsumme der Zahlung muss mit dem Gesamtbetrag übereinstimmen",
  choosePaymentMethod: "Zahlungsmethode auswählen",
  youCanOnlyEnterNumbers: "Sie können nur Zahlen eingeben",
  atLeast7Characters: "Muss mindestens 7 Zeichen lang sein",
  exportError: "Fehler beim Exportieren. Bitte versuchen Sie es erneut.",
  fieldIsRequired: "Feld ist erforderlich",
  successfullyModifiedIngredient: "Zutat erfolgreich geändert",
  successfullyCreatedIngredient: "Zutat erfolgreich erstellt",
  errorCreatingIngredient: "Fehler beim Erstellen der Zutat",
  errorModifyingIngredient: "Fehler beim Ändern der Zutat",
  print: "Drucken",
  addingProductService: "Hinzufügen von Produkt/Dienstleistung",
  factureEdit: "Rechnung bearbeiten",
  purchasePriceExclVAT: "Kaufpreis exkl. MwSt.",
  companyRegistrationNumber: "Firmenregistrierungsnummer",
  jbkjs: "jbkjs",
  accountant: "Buchhalter",
  item: "Artikel",
  settings: "Einstellungen",
  editIngredient: "Zutat bearbeiten",
  accountSettings: "Kontoeinstellungen",
  myInvoices: "Meine Rechnungen",
  companyName: "Firmenname",
  filteredTable: "Gefilterte Tabelle",
  export: "Exportieren",
  download: "Herunterladen",
  pibNotFoundInAPR: "PIB nicht im APR gefunden.",
  exportTablePrompt: "Möchten Sie die gesamte oder gefilterte Tabelle exportieren?",
  supplierWithEnteredPIBExists: "Ein Lieferant mit der eingegebenen PIB existiert bereits!",
  supplierSuccessfullyModified: "Lieferant erfolgreich geändert",
  supplierSuccessfullyAdded: "Lieferant erfolgreich hinzugefügt",
  enterPIBToFillInData: "Geben Sie PIB ein, um Daten aus APR zu füllen",
  addAtLeastOneProduct: "Fügen Sie mindestens ein Produkt hinzu",
  minimumQuantityIs1: "Mindestens Menge ist 1",
  invalidDate: "Ungültiges Datum",
  invalidDateFrom: "Ungültiges Datum von",
  invalidDateTo: "Ungültiges Datum bis",
  expenses: "Ausgaben",
  totalBase: "Gesamtbasis",
  rawMaterial: "Rohmaterial",
  totalRebate: "Gesamtrabatt",
  ingredientRatio: "Zutatenverhältnis",
  totalVAT: "Gesamte MwSt.",
  writeOffTime: "Abschreibungszeit",
  forPayment: "Zur Zahlung",
  normative: "Normativ",
  addExpense: "Ausgabe hinzufügen",
  addDiscount: "Rabatt hinzufügen",
  addIngredient: "Zutat hinzufügen",
  addUser: "Benutzer hinzufügen",
  createFacture: "Rechnung erstellen",
  newIngredient: "Neue Zutat",
  ingredients: "Zutaten",
  ingredient: "Zutat",
  addProduct: "Produkt hinzufügen",
  newProduct: "Neues Produkt",
  enterProductFirst: "Sie müssen zuerst ein Produkt eingeben",
  enterIngredientFirst: "Sie müssen zuerst eine Zutat eingeben",
  enterExpenseFirst: "Sie müssen zuerst eine Ausgabe eingeben",
  reportByFilter: "Bericht nach Filter",
  change: "Ändern",
  publishing: "Veröffentlichen",
  select: "Auswählen",
  createdBy: "Erstellt von",
  hide: "Verbergen",
  additionalOptions: "Zusätzliche Optionen",
  newExpense: "Neue Ausgabe",
  creatingClientSupplier: "Kunde/Lieferant erstellen",
  expenseCreation: "Erstellung von Ausgaben",
  selectAccounts: "Konten auswählen",
  errorOccurred: "Ein Fehler ist aufgetreten!",
  clientSupplier: "Kunde/Lieferant",
  clientsCustomers: "Kunden",
  reports: "Berichte",
  fullScreen: "Vollbild",
  minimize: "Minimieren",
  syncCompleted: "Abgeschlossene Synchronisation für den ausgewählten Zeitraum",
  syncError: "Fehler bei der Synchronisation",
  owe: "Schulden",
  time: "Zeit",
  searchProduct: "Produkt suchen",
  searchClientSupplier: "Kunde oder Lieferant suchen",
  date: "Datum",
  table: "Tabelle",
  claims: "Ansprüche",
  balance: "Balance",
  licenseDuration: "Lizenzdauer",
  note: "Hinweis",
  icons: "Symbole",
  codes: "Codes",
  viewItems: "Artikel anzeigen",
  chooseItemDisplay: "Wählen Sie die gewünschte Artikeldarstellung",
  database: "Datenbank",
  notValidEmail: "keine gültige E-Mail.",
  writtenOffItems: "Abgeschriebene Artikel",
  editSupplier: "Lieferant bearbeiten",
  inventoryHistory: "Bestandshistorie",
  newClientSupplier: "Neuer Kunde/Lieferant",
  newEditClientSupplier: "Neu/Bearbeiten Kunde/Lieferant",
  errorLoadingData: "Fehler beim Laden der Daten",
  costReport: "Kostenbericht",
  ingredientNotFound: "Zutat nicht gefunden, bitte erneut versuchen!",
  passwordUsernameUsedByAnotherOperator: "Dieses Passwort oder dieser Benutzername wird bereits von einem anderen Operator verwendet.",
  listOfClientsSuppliers: "Liste der Kunden/Lieferanten",
  clientSupplierModification: "Kunde/Lieferant-Änderung",
  selectAtLeastOneAccount: "Wählen Sie mindestens 1 Konto aus",
  enterSearchParameter: "Geben Sie den Suchparameter ein",
  errorDuringSearch: "Fehler bei der Suche!",
  unableToRetrieveGroups: "Abrufen von Gruppen nicht möglich!",
  unableToRetrieveOperators: "Abrufen von Operatoren nicht möglich!",
  totalSalesForThisMonth: "Gesamtumsatz für diesen Monat",
  totalRefundForThisMonth: "Gesamterstattung für diesen Monat",
  totalEarningsForThisMonth: "Gesamteinnahmen für diesen Monat",
  annualTurnover: "Jahresumsatz",
  totalSoldFoodBeveragesOther: "Insgesamt verkaufte Lebensmittel, Getränke und andere",
  food: "Lebensmittel",
  remaining: "Verbleibend",
  createNew: "Neu erstellen",
  list: "Liste",
  itemExample: "Artikelbeispiel",
  notValid: "Nicht gültig",
  itemName: "Artikelname",
  issueDate: "Ausgabedatum",
  priceWithVAT: "Preis inkl. MwSt.",
  dateAndTime: "Datum und Uhrzeit",
  selectPaymentMethod: "Wählen Sie eine Zahlungsmethode",
  closeOrMergeAdvanceInvoices: "Offene Vorschussrechnungen schließen oder zusammenführen",
  selectDateAndAmountPerRate: "Wählen Sie ein Datum und geben Sie den Betrag pro Satz ein",
  totalAmountMismatch: "Die Gesamtsumme pro Steuersatz stimmt nicht mit der Gesamtzahlungsmethode überein!",
  unableToFindInvoice: "Die Rechnung konnte nicht gefunden werden! Kontaktieren Sie den Support.",
  priceSuccessfullyUpdated: "Preis erfolgreich aktualisiert",
  errorUpdatingPrice: "Fehler beim Aktualisieren des Preises!",
  appendingOrIssuingFinal: "Rechnung anhängen oder endgültig ausstellen",
  productViewEdit: "Produkt anzeigen/bearbeiten",
  invoiceOverview: "Rechnungsübersicht",
  invalidInvoiceContactSupport: "Die Rechnung ist ungültig. Bitte kontaktieren Sie den Support!",
  totalProductAmountCannotBeLess: "Die Gesamtproduktmenge darf nicht kleiner sein als der gezahlte Betrag!",
  exampleAdvanceInvoiceOldFiscalization: "Beispiel für eine Vorschussrechnung unter alter Fiskalisierung",
  noAccountsAssociatedWithInvoice: "Derzeit sind keine Konten mit dieser Rechnung verknüpft",
  noInvoices: "Keine Rechnungen",
  newPrice: "Neuer Preis",
  invoiceList: "Rechnungsliste",
  itemOverviewEdit: "Artikelübersicht/bearbeiten",
  successfullyCompletedCompensation: "Sie haben die Kompensation erfolgreich abgeschlossen",
  successfullyRemovedCompensation: "Sie haben die Kompensation erfolgreich entfernt",
  tax: "Steuer",
  taxExample: "Gruppe: A Steuer: 0%",
  clients: "Kunden",
  priceEdit: "Preis bearbeiten",
  exit: "Beenden",
  error: "Fehler",
  confirm: "Bestätigen",
  search: "Suchen",
  tin: "TIN",
  invoiceFrom: "Rechnung von",
  paymentCurrency: "Zahlungswährung",
  type: "Typ",
  accountNumber: "Kontonummer",
  months: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
  beverage: "Getränk",
  other: "Andere",
  cash: "Bargeld",
  card: "Karte",
  check: "Scheck",
  wireTransfer: "Überweisung",
  voucher: "Gutschein",
  mobileMoney: "Mobiles Geld",
  cashier: "Kassierer",
  country: "Land",
  sales: "Verkäufe",
  top: "Top",
  email: "E-Mail",
  address: "Adresse",
  city: "Stadt",
  user: "Benutzer",
  number: "Nummer",
  totalSales: "Gesamtumsatz",
  totalRefund: "Gesamterstattung",
  password: "Passwort",
  addingSupplier: "Lieferant hinzufügen",
  signOut: "Abmelden",
  deleting: "Löschen",
  accept: "Akzeptieren",
  chosen: "Ausgewählt",
  theme: "Thema",
  new: "Neu",
  old: "Alt",
  youHave: "Du hast",
  unreadMessages: "ungelesene Nachrichten",
  MarkAllAsRead: "Alle als gelesen markieren",
  surname: "Nachname",
  notifications: "Benachrichtigungen",
  showAll: "Alle anzeigen",
  jsonFileInterrupted: "JSON-Datei unterbrochen",
  importSuccess: "Import erfolgreich",
  downloadJsonFileErrors: "Datei mit fehlgeschlagenen JSON-Objekten herunterladen",
  importErrors: "Importfehler",
  dragFileHereOrClick: "Ziehe die Datei hierhin oder klicke",
  invoiceImport: "Rechnungsimport",
  chooseLightOrDark: "Wähle ein helles oder dunkles Thema",
  successSaved: "Erfolgreich gespeichert",
  passwordResetLinkSentSuccessfully: "Passwort-Zurücksetzungslink erfolgreich gesendet",
  adjustAppearanceInvoice: "Passe das Erscheinungsbild deiner Rechnung an",
  productDontHaveId: "Produkt hat keine ID",
  startProducts: "Starte Produkte",
  advertisingText: "Werbungstext",
  printLogo: "Logo drucken",
  printTestInvoice: "Drucke eine Musterrechnung",
  turnOnOrOffVirtualKeyboard: "Virtuelle Tastatur ein- oder ausschalten",
  searchProductsServices: "Suche Produkte Dienstleistungen",
  signIn: "Anmelden",
  enterFullVATID: "Geben Sie die vollständige USt-IdNr. ein, um einen neuen Kunden zu finden",
  confirmDelete: "Sind Sie sicher, dass Sie löschen möchten?",
  operators: "Operatoren",
  addOperator: "Operator hinzufügen",
  customerList: "Kundenliste",
  listOperators: "Liste der Operatoren",
  exampleSignInToThePortal: "Beispielhafte Anmeldung im Portal",
  passwordIsRequired: "Passwort ist erforderlich!",
  invalidCredentials: "Eingegebene Zugangsdaten sind ungültig!",
  emailIsRequired: "E-Mail ist erforderlich!",
  emailFormatValidation: "Die E-Mail-Adresse muss im gültigen Format sein!",
  thanLastMonth: " als letzten Monat",
  topSellingProductsForThisMonth: "Top-verkaufte Produkte für diesen Monat",
  topOperatorsForThisMonth: "Top-Operatoren für diesen Monat",
  trafficAnalytics: "Verkehrsanalyse",
  errorMessage: "Es ist ein Fehler aufgetreten",
  listOfOperators: "Liste der Operatoren",
  addCustomers: "Kunden hinzufügen",
  newOperator: "Neuer Operator",
  customerDatabase: "Kundendatenbank",
  inactive: "inaktiv",
  creatingOperator: "Operator erstellen",
  basic: "Grundlegend",
  name: "",
  all: "",
  add: "",
  price: "",
  profile: "",
  advertisements: "",
  categories: "",
  bill: "",
  ppProducts: "",
  noteIsRequired: "",
  printA4: "",
  imageMustBeIn169Format: "",
  noSearchResult: "Kein Suchergebnis",
  noSearchResultFor: "Kein Suchergebnis für",
  pleaseCheckInput: "Bitte prüfen Sie, ob die Eingabe korrekt ist",
  enterKeyword: "Schlüsselwörter eingeben",
  issueAgin: "",
  chooseItems: "",
  issue: "",
  forbidden: "",
  noAcces: "",
  cinJmbg: "",
  choose: "",
  searchPages: "",
  esirNumber: "",
  issuingPlace: "",
  cannotLoadLocations: "",
  cannotLoadTaxRates: "",
  cannotLoadSettings: "",
  cannotLoadBankAccount: "",
  cannotLoadUserImage: "",
  cannotLoadAds: "",
  invoiceSyncError: "",
  locationSuccessAdded: "",
  enterNewName: "",
  enterNewPrice: "",
  a4Preview: "",
  a4Print: "",
  company: "",
  location: "",
  district: "",
  loadingData: "",
  discountEdit: "",
  deleted: "",
  cannotIssueThisInvoice: "",
  itemsCodeDescription: "",
};
export default de;
