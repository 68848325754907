const en = {
  oldFiscalError: "Unesite IBFM ili XXXXXXXX-br. računa",
  oldFiscalHelp:"IBFM ili XXXXXXXX-br. računa",
  onlyDecimals: "You can only enter {{number}} decimals.",
  customerIdentification: "Customer identification",
  customerIdHelper: "ID number or other identification document",
  otherPaymentType: "Other",
  optionalBuyerInfo: "Optional customer field",
  closeInvoice: "Close invoice",
  cancelInvoice: "Cancel invoice",
  issueInvoice: "Issue invoice",
  reportsEFiscal: "eFiscal Reports",
  analyticsEFiscal: "eFiscal Analytics",
  reportEFactures: "eFactures Reports",
  exitConfirmTitle: "Are you sure you want to exit?",
  exitImportProducts: "All imported products will be deleted.",
  uid: "UID",
  dragFileOrOpen: "Drag file here or click",
  deleteExistingProducts: "Delete existing products",
  productImport: "Product import",
  someProductsNotValid: "Some products are not valid!",
  fileNotImported: "File not imported!",
  changeProductOnBillName: "Edit the product name",
  changeProductOnBillPrice: "Enter the new product price",
  changeProductOnBillDiscount: "Enter a product discount",
  invoiceTranslate: {
    ba:{
      tax: "Porez",
      name: "Naziv",
      total: "Ukupno",
      tin: "JIB",
      address: "Adresa",
      forPayment: "Za plaćanje",
      price: "Cijena",
      pfrTime: "PFR vrijeme",
      esirTime: "ESIR vrijeme:",
      refTime: "ESIR vrijeme:"
    },
    sr:{
      tax: "Porez",
      name: "Naziv",
      total: "Ukupno",
      tin: "JIB",
      address: "Adresa",
      forPayment: "Za plaćanje",
      price: "Cena",
      pfrTime: "PFR vreme",
      esirTime: "ESIR vreme:",
      refTime: "ESIR vreme:"
    }
  },
  loginHelmet: "Login | ESIR",
  aboutAppItemsSR: [
    { title: "Manufacturer:", value: "IT Creator SLTS doo, ul. Krunska 24, 11000 Beograd" },
    { title: "Serial number:", value: "20220128" },
    { title: "Software version:", value: "1.0" },
    { title: "Email for support:", value: "podrska@it-creator.sr" },
    { title: "Support number:", value: "+381 11 4444 588" },
    { title: "Website", value: "www.esir.rs" },
    {
      title: "Useful links:", value: [
        {
          title: "User manual for ESIR WIN Fiscal",
          value: "https://it-creator.rs/download/Korisnicko-uputstvo-za-ESIR-WIN-Fiskal.pdf"
        },
        { title: "ESIR WIN Fiscal functions", value: "https://it-creator.rs/download/ESIR-WIN_Fiskal_funkcije.pdf" },
        { title: "ESIR warehouse Instructions", value: "https://it-creator.rs/download/ESIR_Magacin_Uputstvo.pdf" },
        {
          title: "User's guide for issuing and binding ESIR WIN Fiscal advance invoices",
          value: "https://it-creator.rs/download/" +
            "Korisnicko_uputstvo_za_izdavanje_i_vezivanje_avansnih_računa-ESIR-WIN-Fiskal.pdf"
        }
      ]
    }
  ],
  aboutAppItemsBA: [
    { title: "Manufacturer:", value: "IT Creator Solutions doo, ul. Sime Šolaje 1A, 78000 Banja Luka" },
    { title: "Serial number:", value: "20240401" },
    { title: "Software version:", value: "1.1" },
    { title: "Email for support:", value: "podrska@it-creator.ba" },
    { title: "Support number:", value: "051 981 295" },
    { title: "Website", value: "https://elektronskafiskalizacija.ba/" },
    {
      title: "Useful links:", value: [
        {
          title: "User manual for ESIR WIN Fiscal",
          value: "https://it-creator.ba/download/Korisnicko-uputstvo-za-ESIR-WIN-Fiskal.pdf"
        }
      ]
    }
  ],
  loginOperatorHelmet: "Login operator | ESIR",
  operatorsNotFound: "Operators not found...",
  compensation: "Compensation",
  dragOrSelectFile: "Drag or select file",
  dragFilesHereOrClick: "Drag files here or click",
  browse: "browse",
  toAddFileMaxSizeIs: "to add file max size",
  maxNumberOfImagesIs: "Maximum number of images is",
  deleteImage: "Delete image",
  areYouSureYouWantToDeleteImage: "Are you sure to want to delete image?",
  discount: "Discount",
  compensations: "Compensations",
  dashboard: "Dashboard",
  factureNumber: "Facture number",
  factureDetails: "Facture details",
  paid: "Paid",
  factures: "Factures",
  facturePreview: "Facture preview",
  allFactures: "All factures",
  allInvoices: "All invoices",
  withCompensation: "With compensation",
  warehouse: "Warehouse",
  byCompensation: "By compensation",
  unpaid: "Unpaid",
  overdue: "Overdue",
  factureNotFound: "Facture not found, please try again!",
  total: "Total",
  addCompensation: "Add compensation",
  remainingToPay: "Remaining to pay",
  invoiceNumber: "Invoice number",
  editExpenses: "Edit expenses",
  expense: "Expense",
  save: "Save",
  totalPriceExcludingVAT: "Total price excluding VAT",
  totalPriceIncludingVAT: "Total price including VAT",
  discountPerProduct: "Discount (Per product)",
  unitPriceExcludingVAT: "Unit price excluding VAT",
  unitPriceIncludingVAT: "Unit price including VAT",
  priceIsPurchase: "Price is purchase",
  rate: "rate",
  discounts: "Discounts",
  listExpenses: "List of Expenses",
  edit: "Edit",
  clear: "Clear",
  close: "Close",
  newUser: "New user",
  active: "Active",
  costLower: "cost",
  activateLower: "activate",
  cardNumber: "Card number",
  discountUsers: "Discount users",
  listOfDiscounts: "List of discounts",
  deactivateLower: "deactivate",
  areYouSureYouWantTo: "Are you sure you want to",
  expenseModification: "Expense modification",
  activate: "Activate",
  totalAmount: "Total amount",
  referenceNumber: "Reference number",
  statusModification: "Status modification",
  deactivate: "Deactivate",
  deactivated: "Deactivated",
  copy: "Copy",
  details: "Details",
  training: "Training",
  sale: "Sale",
  proforma: "Proforma",
  profitExclVAT: " Profit excl. VAT",
  profitInclVAT: " Profit incl. VAT",
  linkedAdvanceInvoices: "Linked advance invoices",
  advance: "Advance",
  cancellation: "Cancellation",
  counter: "Counter",
  invoice: "Invoice",
  receptionDate: "Reception Date",
  issuingTime: "Issuing time",
  productOrServiceName: "Product or service name",
  issuanceDate: "Issuance date",
  transactionType: "Transaction type",
  invoiceType: "Invoice type",
  refund: "Refund",
  cin: "CIN",
  quotations: "Quotations",
  traffic: "Traffic",
  stockList: "Stock list",
  invoices: "Invoices",
  entrance: "Entrance",
  paymentDate: "Payment date",
  paymentDateTime: "Payment Date and Time",
  purchasePriceWithoutVAT: "Purchase price without VAT",
  purchasePriceWithVAT: "Purchase price with VAT",
  purchaseValueWithVAT: "Purchase value with VAT",
  purchaseValueWithoutVAT: "Purchase value without VAT",
  sellingPriceWithoutVAT: "Selling price without VAT",
  sellingPriceWithVAT: "Selling price with VAT",
  sellingValueWithoutVAT: "Selling value without VAT",
  sellingValueWithVAT: "Selling value with VAT",
  purchaseValueOfOutputWithoutVAT: "Purchase value of output without VAT",
  purchaseValueOfOutputWithVAT: "Purchase value of output with VAT",
  outputTaxRate: "Output tax rate",
  lpfr: "LPFR",
  vpfr: "V-PFR",
  addNew: "Add new",
  contactEmails: "Contact emails",
  addOrChangeContactEmail: "Add/Change contact email",
  developerMode: "Developer mode",
  currentAccount: "Current account",
  virtualKeyboard: "Virtual keyboard",
  invoiceSettings: "Invoice settings",
  trainingModSection: "Training mode section",
  rawMaterials: "Raw materials",
  inputTaxRate: "Input tax rate",
  selectTaxRate: "Select a tax rate!",
  unitPrice: "Unit price",
  lastPurchasePrice: "Last purchase price",
  currentStock: "Current stock",
  unitPriceCalculator: "Unit Price Calculator",
  group: "Group",
  preview: "Preview",
  cancel: "Cancel",
  delete: "Delete",
  action: "Action",
  areYouSureYouWantToDelete: "Are you sure you want to delete?",
  payments: "Payments",
  taxCategory: "Tax category",
  taxRate: "Tax rate",
  aboutApp: "About app",
  remove: "Remove",
  addPaymentMethod: "Add payment method",
  addPayment: "Add payment",
  paymentMethod: "Payment method",
  totalAmountForPayment: "Total amount for payment",
  removeAll: "Remove all",
  append: "Append",
  totalPaid: "Total paid",
  toPay: "To pay",
  turnedOff: "Turned off",
  inStock: "In stock",
  uponExpiration: "Upon expiration",
  notAvailable: "Not available",
  notDefined: "Not defined",
  productList: "Product list",
  products: "Products",
  areYouSureToDelete: "Are you sure you want to delete ",
  productsQuestion: "product(s)?",
  code: "Code",
  show: "Show",
  unit: "Unit",
  category: "Category",
  state: "State",
  product: "Product",
  posted: "Posted",
  supplier: "Supplier",
  paidAmount: "Paid amount",
  receiptDate: "Receipt date",
  valueDate: "Value date",
  printing: "Printing",
  base: "Base",
  sellingPrice: "Selling price",
  printTablePrompt: "Do you want to print the entire or filtered table?",
  savedInvoices: "Saved invoices",
  printingError: "Printing error. Please try again.",
  errorDeletingInvoice: "Error while deleting invoice",
  invoiceDeleted: "Invoice deleted",
  errorLoadingSavedInvoices: "Error while loading saved invoices",
  itemNumber: "Item number",
  finalInvoice: "Final invoice",
  quantity: "Quantity",
  operator: "Operator",
  role: "Role",
  creationDate: "Creation date",
  fiscalize: "Fiscalize",
  discountUserModification: "Discount user modification",
  actions: "Actions",
  newDiscountUser: "New discount user",
  errorWhileDeleting: "Error while deleting",
  invoiceNotFound: "Invoice not found",
  discountUser: "Discount user",
  creatingDiscountUser: "Creating discount user",
  username: "Username",
  amount: "Amount",
  issuanceTime: "Issuance time",
  firstNameLastName: "First name and last name",
  enterCardNumbers: "Enter card numbers (press enter after input)",
  status: "Status",
  unableToDeleteDiscount: "It's not possible to delete this discount.",
  phone: "Phone",
  numberOfProducts: "Number of products",
  contactPerson: "Contact person",
  creatingDiscount: "Creating discount",
  priceWithDiscount: "Price with discount",
  productOrService: "Product/service",
  unitOfMeasure: "Unit of measure",
  allowedFormat: "Allowed format",
  dateFrom: "Date from",
  customerType: "Customer type",
  invoiceCancellation: "Invoice cancellation",
  enterIdentificationNumber: "You must enter an identification number!",
  loading: "Loading",
  submit: "Submit",
  final: "Final",
  saved: "Saved",
  dpu: "DPU",
  warehouseManagement: "Warehouse Management",
  representations: "Representations",
  calculations: "Calculations",
  nonFeeTransactions: "Non-fee Transactions",
  netQuantity: "Net quantity",
  oldFiscalization: "Old fiscalization",
  selectOperator: "Select an operator",
  creatingIngredient: "Creating ingredient",
  supplierName: "Supplier name",
  dateTo: "Date to",
  logout: "Logout",
  analytics: "Analytics",
  salesRecords: "Sales Records",
  analyticsAndReports: "Analytics and Reports",
  ean: "Ean",
  label: "Label",
  initial: "Initial",
  writeOff: "Write-off",
  orderNumber: "Order number",
  deletionTime: "Deletion time",
  changePasswordRequest: "Change password request",
  description: "Description",
  enterFinalInvoiceDetails: "Enter final invoice details",
  lastAdvanceInvoiceNumber: "Last advance invoice number of a specific transaction",
  createInvoiceOldFiscalization: "Creating an invoice under old fiscalization",
  invoiceCreation: "Invoice creation",
  invoiceNumberBuyerVat: "Invoice number \\ Buyer's VAT number...",
  maximumSizeUpTo: "maximum size up to",
  productsServices: "Products/Services",
  purchasePrice: "Purchase price",
  enterOrChangeLpfr: "Enter or change LPFR",
  taxPreview: "Tax preview",
  trainingMode: "Training mode",
  turnOnOrOffTrainingMode: "Turn on or off training mode",
  activeTaxRates: "Active tax rates",
  selectRate: "Select a rate",
  saveImage: "Save image",
  invoiceAlreadyExists: "Invoice already exists under that number",
  totalSumMismatch: "Total sum of products and payment methods does not match!",
  enterAtLeastOnePaymentMethod: "You must enter at least one payment method",
  enterAtLeastOneProduct: "You must enter at least one product",
  operatorModification: "Operator modification",
  supplierSuccessfullyDeleted: "Supplier successfully deleted",
  errorWhileLoadingData: "Error while loading data",
  noData: "No data",
  choosePaymentMethods: "Choose payment methods",
  exportImport: "Export/Import",
  customerCard: "Customer card",
  chooseLocation: "Choose location",
  itemsOnBill: "Items on bill",
  changeQuantity: "Change quantity",
  productName: "Product name",
  saveInDatabase: "Save in database",
  changeProductOnBill: "Change product on bill",
  enter: "Enter",
  trafficSale: "Traffic sale",
  closeDialog: "Close dialog",
  clearAllCategories: "Clear all categories",
  productNotFound: "Product not found",
  dpuList: "DPU list",
  dpuForDate: "DPU for date",
  serialNumber: "Serial Number",
  dishAndBeverageName: "Dish and Beverage Name for On-site Consumption",
  transferredQuantity: "Transferred Quantity",
  procuredQuantity: "Procured Quantity",
  endingStock: "Ending Stock",
  consumedQuantityDuringDay: "Consumed Quantity During Day (7-8)",
  unitPriceWithVAT: "Unit Price with VAT",
  servicesRevenue: "Services Revenue (9x10)",
  totalRevenueFromDishesAndBeverages: "Total Revenue from Dishes and Beverages for On-site Consumption (6x10)",
  beveragesRevenue: "Beverages Revenue",
  dishesRevenue: "Dishes Revenue",
  difference: "Difference",
  selectSupplier: "Select a supplier",
  inventory: "Inventory",
  stockListForDate: "Stock list for date",
  cardOverview: "Card overview",
  expected: "Expected",
  measured: "Measured",
  listOfCustomers: "List of customers",
  averagePurchasePrice: "Average purchase price",
  totalQuantity: "Total quantity",
  suppliers: "Suppliers",
  bookIt: "Book it",
  unBook: "Unbook",
  productCard: "Product card",
  id: "Id",
  split: "Split",
  factureWithThisNumberAlreadyExist: "Facture with this number already exist",
  moreThanRemainingPayment: "Uneli ste veći iznos od preostalog iznosa za uplatu",
  supplierHasActiveFactures: "The supplier has active Factures. Deletion is not possible!",
  openAdvances: "Open advances",
  selectAtLeastOneProduct: "Select at least 1 product",
  selectDateAndEnterAmount: "Select a date and enter the amount per rate",
  paymentTotalMustMatch: "Payment total must match the total amount",
  choosePaymentMethod: "Choose payment method",
  youCanOnlyEnterNumbers: "You can only enter numbers",
  atLeast7Characters: "Must be at least 7 characters",
  exportError: "Error during export. Please try again.",
  fieldIsRequired: "Field is required",
  successfullyModifiedIngredient: "Successfully modified ingredient",
  successfullyCreatedIngredient: "Successfully created ingredient",
  errorCreatingIngredient: "Error creating ingredient",
  errorModifyingIngredient: "Error modifying ingredient",
  print: "Print",
  addingProductService: "Adding product/service",
  factureEdit: "Facture edit",
  purchasePriceExclVAT: "Purchase Price excl. VAT",
  companyRegistrationNumber: "Company registration number",
  jbkjs: "jbkjs",
  accountant: "Accountant",
  item: "Item",
  settings: "Settings",
  editIngredient: "Edit Ingredient",
  accountSettings: "Account settings",
  myInvoices: "My invoices",
  companyName: "Company name",
  filteredTable: "Filtered table",
  export: "Export",
  download: "Download",
  pibNotFoundInAPR: "PIB not found in the APR.",
  exportTablePrompt: "Do you want to export the entire or filtered table?",
  supplierWithEnteredPIBExists: "A supplier with the entered PIB already exists!",
  supplierSuccessfullyModified: "Supplier successfully modified",
  supplierSuccessfullyAdded: "Supplier successfully added",
  enterPIBToFillInData: "Enter PIB to fill in data from APR",
  addAtLeastOneProduct: "Add at least one product",
  minimumQuantityIs1: "Minimum quantity is 1",
  invalidDate: "Invalid date",
  invalidDateFrom: "Invalid date from",
  invalidDateTo: "Invalid date to",
  expenses: "Expenses",
  totalBase: "Total base",
  rawMaterial: "Raw material",
  totalRebate: "Total rebate",
  ingredientRatio: "Ingredient ratio",
  totalVAT: "Total VAT",
  writeOffTime: "Write-off time",
  forPayment: "For payment",
  normative: "Normative",
  addExpense: "Add expense",
  addDiscount: "Add Discount",
  addIngredient: "Add ingredient",
  addUser: "Add User",
  createFacture: "Create facture",
  newIngredient: "New ingredient",
  ingredients: "Ingredients",
  ingredient: "Ingredient",
  addProduct: "Add product",
  newProduct: "New product",
  enterProductFirst: "You must first enter a product",
  enterIngredientFirst: "You must first enter an ingredient",
  enterExpenseFirst: "You must first enter an expense",
  reportByFilter: "Report by filter",
  change: "Change",
  publishing: "Enable issuing",
  select: "Select",
  createdBy: "Created by",
  hide: "Hide",
  additionalOptions: "Additional options",
  newExpense: "New expense",
  creatingClientSupplier: "Creating client/supplier",
  expenseCreation: "Expense creation",
  selectAccounts: "Select accounts",
  errorOccurred: "An error has occurred!",
  clientSupplier: "Client/Supplier",
  clientsCustomers: "Clients / Customers",
  reports: "Reports",
  fullScreen: "Full screen",
  minimize: "Minimize",
  syncCompleted: "Completed synchronization for the selected period",
  syncError: "An error occurred while synchronizing",
  owe: "Owes",
  time: "Time",
  searchProduct: "Search product",
  searchClientSupplier: "Search client or supplier",
  date: "Date",
  table: "Table",
  claims: "Claims",
  balance: "Balance",
  licenseDuration: "License duration",
  note: "Note",
  icons: "Icons",
  codes: "Codes",
  viewItems: "viewItems",
  chooseItemDisplay: "Choose the desired item display",
  database: "Database",
  notValidEmail: "not valid email.",
  writtenOffItems: "Written-off items",
  editSupplier: "Edit supplier",
  inventoryHistory: "Inventory history",
  newClientSupplier: "New client/supplier",
  newEditClientSupplier: "New/edit client/supplier",
  errorLoadingData: "Error while loading data",
  costReport: "Cost Report",
  ingredientNotFound: "Ingredient not found, please try again!",
  passwordUsernameUsedByAnotherOperator: "This password or username is already used by another operator.",
  listOfClientsSuppliers: "List of clients/suppliers",
  clientSupplierModification: "Client/Supplier modification",
  selectAtLeastOneAccount: "Select at least 1 account",
  enterSearchParameter: "Enter search parameter",
  errorDuringSearch: "Error during search!",
  unableToRetrieveGroups: "Unable to retrieve groups!",
  unableToRetrieveOperators: "Unable to retrieve operators!",
  totalSalesForThisMonth: "Total sales for this month",
  totalRefundForThisMonth: "Total refund for this month",
  totalEarningsForThisMonth: "Total earnings for this month",
  annualTurnover: "Annual turnover",
  totalSoldFoodBeveragesOther: "Total sold food, beverages, and other",
  food: "Food",
  remaining: "Remaining",
  createNew: "Create new",
  list: "List",
  itemExample: "Item example",
  notValid: "Not valid",
  itemName: "Item name",
  issueDate: "Issue date",
  priceWithVAT: "Price with VAT",
  dateAndTime: "Date and time",
  selectPaymentMethod: "Select a payment method",
  closeOrMergeAdvanceInvoices: "Closing or merging open advance invoices",
  selectDateAndAmountPerRate: "Select a date and enter the amount per rate",
  totalAmountMismatch: "Total amount per tax rate does not match the total payment method amount!",
  unableToFindInvoice: "Unable to find the invoice! Contact support.",
  priceSuccessfullyUpdated: "Price successfully updated",
  errorUpdatingPrice: "Error while updating price!",
  appendingOrIssuingFinal: "Appending invoice or issuing final",
  productViewEdit: "Product view/edit",
  invoiceOverview: "Invoice overview",
  invalidInvoiceContactSupport: "Invoice is not valid. Please contact support!",
  totalProductAmountCannotBeLess: "Total product amount cannot be less than paid amount!",
  exampleAdvanceInvoiceOldFiscalization: "Example of advance invoice under old fiscalization",
  noAccountsAssociatedWithInvoice: "Currently, there are no accounts associated with this invoice",
  noInvoices: "No invoices",
  newPrice: "New price",
  invoiceList: "Invoice list",
  itemOverviewEdit: "Item overview/edit",
  successfullyCompletedCompensation: "You have successfully completed the compensation",
  successfullyRemovedCompensation: "You have successfully removed the compensation",
  tax: "Tax",
  taxExample: "Group: A Tax: 0%",
  clients: "Clients",
  priceEdit: "Price edit",
  exit: "Exit",
  error: "Error",
  confirm: "Confirm",
  search: "Search",
  tin: "Tin",
  invoiceFrom: "Invoice from",
  paymentCurrency: "Payment currency",
  type: "Type",
  accountNumber: "Account number",
  months: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"],
  beverage: "Beverage",
  other: "Other",
  cash: "Cash",
  card: "Card",
  check: "Check",
  wireTransfer: "Wire Transfer",
  voucher: "Voucher",
  mobileMoney: "Mobile money",
  cashier: "Cashier",
  country: "Country",
  sales: "Sales",
  top: "Top",
  email: "Email",
  address: "Address",
  city: "City",
  user: "User",
  number: "Number",
  totalSales: "Total sales",
  totalRefund: "Total refund",
  password: "Password",
  addingSupplier: "Adding a supplier",
  signOut: "Sign out",
  deleting: "Deleting",
  accept: "Accept",
  chosen: "Chosen",
  theme: "Theme",
  new: "New",
  old: "Old",
  youHave: "You have",
  unreadMessages: "unread messages",
  MarkAllAsRead: "Mark all as read",
  surname: "Surname",
  notifications: "Notifications",
  showAll: "Show all",
  jsonFileInterrupted: "Json file interrupted",
  importSuccess: "Import success",
  downloadJsonFileErrors: "Download file with failed json objects",
  importErrors: "Import errors",
  dragFileHereOrClick: "Drag file here ort click",
  invoiceImport: "Invoice import",
  chooseLightOrDark: "Choose light or dark theme",
  successSaved: "Success saved",
  passwordResetLinkSentSuccessfully: "Password reset link sent successfully",
  adjustAppearanceInvoice: "Adjust the appearance of your invoice",
  productDontHaveId: "Product dont have id",
  startProducts: "Start products",
  advertisingText: "Advertising text",
  printLogo: "Print logo",
  printTestInvoice: "Print a sample invoice",
  turnOnOrOffVirtualKeyboard: "Turn on or off virtual keyboard",
  searchProductsServices: "Search products services",
  signIn: "Sign in",
  enterFullVATID: "Enter the full VAT ID to find a new customer",
  confirmDelete: "Are you sure you want to delete?",
  operators: "Operators",
  addOperator: "Add Operator",
  customerList: "Customer List",
  listOperators: "List of Operators",
  exampleSignInToThePortal: "Example sign-in to the portal",
  passwordIsRequired: "Password is required!",
  invalidCredentials: "Entered credentials are not valid!",
  emailIsRequired: "Email is required!",
  emailFormatValidation: "Email address must be in a valid format!",
  thanLastMonth: " than last month",
  topSellingProductsForThisMonth: "Top-selling products for this month",
  topOperatorsForThisMonth: "Top operators for this month",
  trafficAnalytics: "Traffic analytics",
  errorMessage: "An error has occurred",
  listOfOperators: "List of operators",
  addCustomers: "Add Customers",
  newOperator: "New operator",
  customerDatabase: "Customers",
  inactive: "inactive",
  creatingOperator: "Creating operator",
  basic: "Basic",
  name: "Name",
  all: "All",
  add: "Add",
  price: "Price",
  profile: "Profile",
  advertisements: "Advertisements",
  categories: "Categories",
  bill: "Bill",
  ppProducts: "products",
  noteIsRequired: "Note is required!",
  printA4: "Print A4",
  imageMustBeIn169Format: "Image must be in 16:9 format",
  noSearchResult: "No search result",
  noSearchResultFor: "No search result for",
  pleaseCheckInput: "Please check if the entry is correct",
  enterKeyword: "Enter keywords",
  issueAgin: "Issue agin",
  chooseItems: "Choose items",
  issue: "Issue",
  forbidden: "Forbidden",
  noAcces: "No access",
  cinJmbg: "CIN/ID",
  choose: "Choose",
  searchPages: "Search page",
  esirNumber: "Esir number",
  issuingPlace: "Issuing place",
  cannotLoadLocations: "Cannot load locations!",
  cannotLoadTaxRates: "Cannot load tax rates!",
  cannotLoadSettings: "Cannot load settings!",
  cannotLoadBankAccount: "Cannot load bank account!",
  cannotLoadUserImage: "Cannot load logo!",
  cannotLoadAds: "Cannot load ads!",
  invoiceSyncError: "Cannot sync invoices!",
  locationSuccessAdded: "Location success added!",
  enterNewName: "Enter new name",
  enterNewPrice: "Enter new price",
  a4Preview: "A4 preview",
  a4Print: "A4 printing",
  company: "Company",
  location: "Location",
  district: "District",
  productOrProducts: "product's",
  loadingData: "Loading data",
  discountEdit: "Edit discount",
  deleted: "Deleted",
  cannotIssueThisInvoice: "Cannot issue this invoice",
  itemsCodeDescription: "(QUANTITY * PRODUCT CODE)",
  newProductService: "New product/service",
  alreadyHaveCode: "There is already a product/service with this internal code",
  addNormative: "Add normative",
  sold: "Sold",
  refunded: "Refunded",
  yearSell: "Annual sale",
  contact: "Contact",
  jbkjc: "JBKJS",
  eFactures: "eInvoices",
  exitInvoices: "Exit invoices",
  newExitInvoice: "New exit invoice",
  incomingInvoices: "Incoming invoices",
  individualRecords: "Individual records",
  newIndividualRecord: "New individual record",
  summaryRecords: "Summary records",
  newSummaryRecord: "New summary record",
  customerAlreadyExist: "There is already a customer with this VAT/ID number",
  a4Email: "Email for display on a4 format",
  companyInfo: "Company details",
  basicInfo: "Account settings",
  saveBasicInfo: "Save account settings",
  saveCompanyInfo: "Save company settings",
  yes: "Yes",
  no: "No",
  overview: "Overview",
  allF: "All",
  totalTaxForThisPeriod: "Total tax for this period",
  totalBasicForThisPeriod: "Total base for this period",
  editProductService: "Edit product/service",
  chooseProductService: "Choose product/service",
  reportFor: "Report for",
  month: "Month",
  year: "Year",
  itemReport: "Item report",
  downloadExcel: "Download excel",
  downloadImage: "Download image",
  dateFromNotValid: "Date from is not valid",
  dateToNotValid: "Date to is not valid",
  dateToBeforeFrom: "Date from is greater than date to",
  ingName: "Ingredient name",
  taxVat: "Tax rate",
  dateOfIssue: "Date of issue",
  kitchen: "Kitchen",
  bar: "Bar",
  bartender: "Bartender",
  driver: " Driver",
  parking: " Parking",
  adminTourist: "Admin Tourist",
  operatorTourist: "Operator Tourist",
  waiter: "Waiter",
  chef: "Chef",
  admin: "Administrator",
  manager: "Manager",
  services: "Services",
  eFactureAdmin: "eInvoices Admin",
  eFactureExitDocuments: "eInvoices exit documents",
  eFactureEnterDocuments: "eInvoices enter documents",
  eFactureUser: "eInvoices User",
  eFactureRevisor: "eInvoices Revisor",
  goods: "Goods",
  units: ["pc", "kg", "km", "g", "l", "ton", "m2", "m3", "minute", "h", "day", "month", " year", "pair", "sum", "other"],
  licenseExpiredMessage: "Your license is inactive due to non-payment.",
  callTenantMessage: "Please contact your distributor.",
  mustBeGreaterThen: "Must be greater than 0",
  oneItemRequired: "You must choose at least one product",
  productIng: "Product/Ingredient",
  marza: "Margin",
  ruc: "RUC",
  logoutError: "Error while logging out",
  createWriteOff: "Create write-off",
  maxSessionMessage: "The maximum number of active sessions has been reached.",
  file: "File",
  alreadyExist: "already exists!",
  csvInvoice: "Import csv invoice",
  oneFileRequired: "You must choose at least one .csv file!",
  priceWithDiscountWithoutTax: "Price with discount without VAT",
  noOnState: "Not in stock",
  expiration: "At expiration",
  oneOf: "One of",
  fileIsNotValid: "File is not valid!",
  validate: "Validate",
  profit: "Profit",
  withTax: "With VAT",
  withoutTax: "Without VAT",
  periodFrom: "Period from",
  period: "Period",
  filter: "Filter",
  chooseDates: "Choose dates",
  chooseMinPeriod: "Choose a smaller period!",
  stateOverview: "State overview",
  yesterdayOverview: "Yesterday’s overview",
  overviewByDate: "Overview by date",
  totalInvoiceNumber: "Total number of issued invoices",
  JID: "JID",
  trafficByLocation: "Traffic by location",
  saleProduct: "Sold products/services",
  refundProduct: "Refunded products/services",
  paymentTypes: "Payment methods",
  cashiers: "Cashiers",
  taxByVat: "Tax amount by tax rates",
  summary: "Summary",
  taxBalance: "Tax balance",
  totalTaxForRefund: "Total tax for refund",
  totalTaxForSale: "Total tax for sale",
  totalSaleBalance: "Total sale balance",
  totalRefundBalance: "Total refund amount",
  totalAmountOfSale: "Total amount of sale",
  firmName: "Firm name",
  zipCode: "Zip code",
  reportForPeriod: "Report for period",
  to: "to",
  reportDetails: "Report details",
  sellItems: "Sold items",
  errorWithExport: "Error with export. Please try again.",
  report: "report",
  allReport: "Full report",
  allDetails: "With all details",
  partialReport: "Partial report",
  onlyBalance: "Only summary",
  reportB: "Report",
  tehnicalSupport: "Technical support available: ",
  signInTitle: "Sign in to WIN Fiskal",
  welcomeText: "Welcome to WIN Fiskal - The fiscalization you need",
  detailedOn: "More details on: ",
  or: " or ",
  percentage: "Percentage",
  inputDiscount: "Enter discount in ",
  enterDiscount: "Enter discount amount",
  newPriceCannotBeBelovedZero: "New price cannot be below zero!",
  checkOnline: "Check online",
  notFound: "Not found",
  prepTime: "Preparation",
  qtyForAlarm: "Quantity for alarm",
  productType: "Product type",
  disableDiscount: "Disable discount",
  turnOffProduct: "Turn off product",
  productIsNormative: "Product is normative",
  productHaveNormative: "Product has normative",
  addProductNormativeDesc: "If you choose normative, the quantity is not added for the product.",
  draft: "Draft",
  recorded: "Recorded",
  replaced: "Replaced",
  cancelled: "Cancelled",
  statusChangeDate: "Date of status change",
  success: "Success",
  send: "Send",
  russian: "russian",
  serbian: "serbian",
  english: "english",
  customers: "Customers",
  referrals: "Referrals",
  commingSoon: "Soon",
  prevousMonthPrecentage: "than last month",
  lastSaleTransactions: "Recent sales transactions",
  trafficExitFactures: "Turnover of outgoing factures",
  trafficEnterFactures: "Turnover of incoming factures",
  permissionDenied: "Permission Denied",
  notHavePermission: "You do not have permission to access this page",
  back: "Back",
  connect: "Connect",
};

export default en;
