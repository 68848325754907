import Dexie from "dexie";
import { getSessionAppVersion, getSessionOperator } from "../../helper/session";
import moment from "moment";
import { INVOICE_TYPE, TRANSACTION_TYPE } from "../../constants";
import { get_browser, sortByDateDescending } from "../../helper/other";
import { doc, setDoc } from "firebase/firestore";
import { AUTH, DB } from "../../auth/FirebaseContext";
import { getInvoicesByUser, getInvoicesByUserForPeriod } from "../../helper/firestore";
import { OFFLINE_DB_NAME, offline_tables } from "../../constants/OfflineDb";

export const offlineDB = new Dexie(OFFLINE_DB_NAME);

export const createDB = () => {
  offlineDB.version(1).stores({
    all_refunded_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,       
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
    all_accounting_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,       
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        timestamp,
        transactionType,
        isInsertedOffline
        `,
    all_normal_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        timestamp,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
    all_copies_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
    all_training_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,        
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
    all_advance_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,        
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
    all_unhandled_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,       
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
    all_errors: `
        ++id,
        userUid,
        method_name,
        file_name,
        params,
        error
        `,
    checks: `
        uid,
        userUid,
        price,
        qty,
        date,
        items,
        discount
        `,
    costs: `
        uid,
        name,
        group,
        price,
        quantity,
        unit,
        vat,
        isInsertedOffline
        `,
    ingredients: `
        uid,
        name,
        category,
        unit,
        quantity,
        vat,
        isInsertedOffline
        `,
    items: `
        uid,
        name,
        category,
        description,
        ean,
        hide,
        prepTime,
        restaurantGroup,
        restaurantItemsCategory,
        code,
        price,
        unit,
        incomeVat,
        vat,
        quantity,
        minimalQuantity,
        disableDiscount,
        ingredients,
        isInsertedOffline
        `,
    discounts: `
        uid,
        name,
        percentage,
        productsUids,
        isInsertedOffline
        `,
    customers: `
        uid,
        userUid,
        name,
        pib,
        mb,
        address,
        city,
        telephone,
        email,
        contactPerson,
        isInsertedOffline`,
    operators: `
        uid,
        userUid,
        group,
        secret,
        username,
        isInsertedOffline`,
    settings: `
        accounting,
        userUid,
        advance,
        articles,
        card,
        cash,
        change,
        theme,
        virman,
        isInsertedOffline`,
    factures: `
        ++id,
        userUid,
        dateOfIssue,
        items,
        jbkjs,
        number,
        paymentDate,
        supplierUid
        `
  });
  offlineDB.open();
};

// -------------- ITEMS -------------------

export async function getAllItemsOffline() {
  return offlineDB.open().then(async db => {
    const table = db.table(offline_tables.items);
    if (table) {
      return table.toArray();
    }
    return [];
  });
}

export async function putItemsOffline(items = []) {
  return offlineDB.open().then(async db => {
    const table = db.table(offline_tables.items);
    if (table) {
      for (const item of items) {
        const offlineItem = await table.get(item.uid);
        table.put({
          ...offlineItem,
          ...item,
          userUid: AUTH.currentUser.uid
        });
      }
    }
  });
}

// -------------- INGREDIENTS -------------------
export async function putIngredientOffline(ingredient) {
  if (!ingredient.uid) {
    ingredient = {
      ...Object.values(ingredient)[0],
      uid: Object.keys(ingredient)[0]
    };
  }
  return offlineDB.open().then(db => {
    const table = db.table(offline_tables.ingredients);
    try {
      offlineDB.open().then(async _ => {
        if (table) {
          let exists = await table.where("uid").equals(ingredient.uid).first();
          if (!exists) {
            table.put({
              ...ingredient,
              userUid: AUTH.currentUser.uid
            });
          }
        }
      });
    } catch (e) {
      console.error(e, "putIngredientOffline");
    }
  });
}

// -------------- COSTS -------------------
export async function putCostOffline(cost) {
  if (!cost.uid) {
    cost = {
      ...Object.values(cost)[0],
      uid: Object.keys(cost)[0]
    };
  }
  return offlineDB.open().then(db => {
    const table = db.table(offline_tables.costs);
    try {
      offlineDB.open().then(async _ => {
        if (table) {
          let exists = await table.where("uid").equals(cost.uid).first();
          if (!exists) {
            table.put({
              ...cost,
              userUid: AUTH.currentUser.uid
            });
          }
        }
      });
    } catch (e) {
      console.error(e, "putCostOffline");
    }
  });
}

// -------------- DISCOUNTS -------------------
export async function putDiscountOffline(discount) {
  if (!discount.uid) {
    discount = {
      ...discount,
      uid: Object.keys(discount)[0]
    };
  }
  return offlineDB.open().then(db => {
    const table = db.table(offline_tables.discounts);
    try {
      offlineDB.open().then(async _ => {
        if (table) {
          let exists = await table.where("uid").equals(discount.uid).first();
          if (!exists) {
            table.put({
              ...discount,
              userUid: AUTH.currentUser.uid
            });
          }
        }
      });
    } catch (e) {
      console.error(e, "putDiscountOffline");
    }
  });
}

export async function updateItemOffline(item) {
  return offlineDB.open().then(db => {
    const table = db.table(offline_tables.items);
    if (table) {
      return table.update(item.uid, item);
    }
  });
}

export async function updateIngOffline(ing) {
  return offlineDB.open().then(db => {
    const table = db.table(offline_tables.ingredients);
    if (table) {
      return table.update(ing.uid, ing);
    }
  });
}

export async function updateQuantityOffline(uid, quantity, type) {
  try {
    return offlineDB.open().then(async db => {
      let table = db.table(offline_tables.items);
      if (type === "ingrediants") {
        table = db.table(offline_tables.ingredients);
      } else if (type === "costs") {
        table = db.table(offline_tables.costs);
      }
      if (table) {
        let exists = await table.where("uid").equals(uid).first();
        if (exists) {
          return table.update(uid, { quantity: (exists.quantity || 0) + quantity });
        }
      }
    });

  } catch (e) {
    console.error(e, "putDiscountOffline");
  }
}

export async function putItemOffline(item) {
  if (!item.uid) {
    item = {
      ...Object.values(item)[0],
      uid: Object.keys(item)[0]
    };
  }
  return offlineDB.open().then(db => {
    const table = db.table(offline_tables.items);
    try {
      offlineDB.open().then(async _ => {
        if (table) {
          let exists = await table.where("uid").equals(item.uid).first();
          if (!exists) {
            table.put({
              ...item,
              userUid: AUTH.currentUser.uid
            });
          }
        }
      });
    } catch (e) {
      console.error(e, "putItemOffline");
    }
  });
}

export async function getItemImageOffline(uid) {
  return offlineDB.open().then(db => {
    const table = db.table(offline_tables.items);
    if (table) {
      return table.where("uid").equals(uid).first();
    }
    return [];
  });
}

// -------------- INVOICES -------------------
const handleInvoiceType = (invoice) => {
  if (invoice.transactionType === TRANSACTION_TYPE.refund) {
    if (invoice.invoiceType !== INVOICE_TYPE.copy && invoice.invoiceType !== INVOICE_TYPE.proforma
      && invoice.invoiceType !== INVOICE_TYPE.training) {
      return offline_tables.all_refunded_invoices;
    }
  }
  switch (invoice.invoiceType) {
    case INVOICE_TYPE.advance:
      return offline_tables.all_advance_invoices;
    case INVOICE_TYPE.normal:
      return offline_tables.all_normal_invoices;
    case INVOICE_TYPE.proforma:
      return offline_tables.all_accounting_invoices;
    case INVOICE_TYPE.copy:
      return offline_tables.all_copies_invoices;
    case INVOICE_TYPE.training:
      return offline_tables.all_training_invoices;
    default:
      return offline_tables.all_unhandled_invoices;
  }
};

export async function getAllSavedInvoicesOffline() {
  return offlineDB.open().then(db => {
    const table = db.table(offline_tables.checks);
    if (table) {
      return table.toArray();
    }
  });
}

export function deleteSavedInvoiceOffline(uid) {
  return offlineDB.open().then(db => {
    const table = db.table(offline_tables.checks);
    if (table) {
      return table.delete(uid);
    }
  });
}

export function saveInvoiceOffline(check) {
  return offlineDB.open().then(db => {
    const table = db.table(offline_tables.checks);
    if (table) {
      return table.put({
        ...check,
        userUid: AUTH.currentUser.uid
      });
    }
  });
}


export async function insertAllUserInvoicesOffline() {
  const currentDate = moment();
  const firstDayOfPreviousMonth = moment(currentDate).subtract(1, "month").startOf("month");
  const fDayOfPrevMonth = firstDayOfPreviousMonth.format("YYYY-MM-DD");
  return new Promise(async (resolve, reject) => {
    await getInvoicesByUser(null, [], resolve, reject, fDayOfPrevMonth);
  });
}

export async function insertAllUserInvoicesOfflineForPeriod(period) {
  return new Promise(async (resolve, reject) => {
    await getInvoicesByUserForPeriod([], resolve, reject, period);
  });
}

export async function getFirstInsertedOfflineInvoice() {
  try {
    return offlineDB.open().then(async db => {
      let tables = [
        offline_tables.all_accounting_invoices,
        offline_tables.all_refunded_invoices,
        offline_tables.all_normal_invoices,
        offline_tables.all_training_invoices,
        offline_tables.all_copies_invoices,
        offline_tables.all_advance_invoices,
        offline_tables.all_advance_open_invoices,
        offline_tables.all_advance_old_invoices];
      let firstInvoice;
      for (const table1 of db.tables) {
        if (tables.includes(table1.name)) {
          let invoice = await table1.orderBy("sdcDateTime")
            .filter((item) => item.userUid === AUTH.currentUser.uid && item.isInsertedOffline === false)
            .first();
          if (!firstInvoice) {
            firstInvoice = invoice;
          } else if (invoice) {
            if (moment(invoice.sdcDateTime).isBefore(moment(firstInvoice.sdcDateTime))) {
              firstInvoice = invoice;
            }
          }
        }
      }
      return firstInvoice;
    });
  } catch (e) {
    console.error("getFirstInsertedOfflineInvoice", e);
    return undefined;
  }
}

export async function getRefundedOfflineInvoiceByReferentDocumentNumber(referentDocumentNumber) {
  return offlineDB.open().then(async db => {
    let table = db.table(offline_tables.all_refunded_invoices);
    return table.where("referentDocumentNumber").equalsIgnoreCase(referentDocumentNumber).first();
  });
}

export async function getLastInsertedOfflineInvoice() {
  try {
    return offlineDB.open().then(async db => {
      let tables = [offline_tables.all_accounting_invoices, offline_tables.all_refunded_invoices,
        offline_tables.all_normal_invoices, offline_tables.all_training_invoices,
        offline_tables.all_copies_invoices];
      let lastInvoice;
      for (const table1 of db.tables) {
        if (tables.includes(table1.name)) {
          let invoice = await table1.orderBy("sdcDateTime")
            .filter((item) => item.userUid === AUTH.currentUser.uid && item.isInsertedOffline === false)
            .reverse().first();
          if (!lastInvoice) {
            lastInvoice = invoice;
          } else if (invoice) {
            if (moment(invoice.sdcDateTime).isAfter(moment(lastInvoice.sdcDateTime))) {
              lastInvoice = invoice;
            }
          }
        }
      }
      return lastInvoice;
    });
  } catch (e) {
    console.error("getLastInsertedOfflineInvoice", e);
    return undefined;
  }
}

export async function putInvoiceOffline(invoice) {
  try {
    let tableName = handleInvoiceType(invoice);
    offlineDB.open().then(async db => {
      const table = db.table(tableName);
      if (table) {
        let splitInvoiceNumber = invoice.invoiceNumber.split("-");
        let locationUserUid = splitInvoiceNumber[0] + AUTH.currentUser.uid;
        let exist = await table.where("invoiceNumber").equals(invoice.invoiceNumber).first();
        if (!exist) {
          table.put({
            ...invoice,
            userUid: AUTH.currentUser.uid,
            userLocation: locationUserUid
          });
        }
      }
    });
  } catch (e) {
    console.error("putInvoiceOffline", e);
  }
}

export async function getAllOfflineInvoiceForReportBySdcDateTime(dateFrom, dateTo, location) {
  return offlineDB.open().then(async db => {
    let tables = [offline_tables.all_refunded_invoices, offline_tables.all_normal_invoices];
    let all = [];
    for (const table1 of db.tables) {
      if (tables.includes(table1.name)) {
        let invoices;
        if (location) {
          invoices = await table1.where("sdcDateTime").between(dateFrom, dateTo)
            .filter(item => {
              return item.userUid === AUTH.currentUser.uid && item.invoiceNumber.startsWith(location);
            }).toArray();
        } else {
          invoices = await table1.where("sdcDateTime").between(dateFrom, dateTo)
            .filter(item => {
              return item.userUid === AUTH.currentUser.uid;
            }).toArray();
        }
        all = [...all, ...invoices];
      }
    }
    return all;
  });
}

export async function getAllOfflineInvoiceByBuyerTin(buyerTin) {
  return offlineDB.open().then(async db => {
    let invoice = await db.table(offline_tables.all_normal_invoices)
      .filter(item => item.userUid === AUTH.currentUser.uid && item.buyerTin?.includes(buyerTin)).toArray();
    if (invoice) {
      return invoice;
    }
    return [];
  });
}

export async function getAllOfflineInvoicesFromTables() {
  try {
    return offlineDB.open().then(async db => {
      let tables = [offline_tables.all_accounting_invoices,
        offline_tables.all_normal_invoices,
        offline_tables.all_training_invoices,
        offline_tables.all_advance_invoices,
        offline_tables.all_copies_invoices,
        offline_tables.all_refunded_invoices];
      const arr = [];
      for (const table1 of db.tables) {
        if (tables.includes(table1.name)) {
          let invoice = await table1.toArray();
          arr.push(invoice);
        }
      }
      const hash = {};
      const merged = [];

      arr.forEach((arr) => {
        arr.forEach((data) => {
          if (!hash[data.invoiceNumber]) {
            hash[data.invoiceNumber] = true;
            merged.push(data);
          }
        });
      });
      return sortByDateDescending(merged);
    });
  } catch (e) {
    console.error("getLastInsertedOfflineInvoice", e);
    return undefined;
  }
}

export async function setIsReferencedOnInvoiceOffline(invoiceNumber) {
  let invoice = await getOfflineInvoiceByInvoiceNumber(invoiceNumber);
  if (invoice) {
    const internalData = invoice.internalData || {};
    internalData.isReferenced = true;
    let tableName = handleInvoiceType(invoice);
    offlineDB.open().then(async db => {
      const table = db.table(tableName);
      if (table) {
        return table.update(invoiceNumber, invoice);
      }
    });
  }
}

export async function getOfflineInvoiceByInvoiceNumber(invoiceNumber) {
  return offlineDB.open().then(async db => {
    const tables = [offline_tables.all_accounting_invoices, offline_tables.all_refunded_invoices,
      offline_tables.all_normal_invoices, offline_tables.all_training_invoices, offline_tables.all_advance_invoices,
      offline_tables.all_copies_invoices];
    for (const table1 of db.tables) {
      if (tables.includes(table1.name)) {
        let invoice = await table1.where("invoiceNumber").equals(invoiceNumber).first();
        if (invoice) {
          return invoice;
        }
      }
    }
    return undefined;
  });
}

export async function getOfflineInvoicesByRefNumber(invoiceNumber) {
  return offlineDB.open().then(async db => {
    const tables = [offline_tables.all_accounting_invoices, offline_tables.all_refunded_invoices,
      offline_tables.all_normal_invoices, offline_tables.all_training_invoices, offline_tables.all_advance_invoices,
      offline_tables.all_copies_invoices];
    for (const table1 of db.tables) {
      if (tables.includes(table1.name)) {
        return table1.where("referentDocumentNumber").equals(invoiceNumber).toArray();
      }
    }
    return undefined;
  });
}

export async function getCompensationInvoiceByInvoiceNumberOrBuyerId(searchParam, searchBuyer) {
  return offlineDB.open().then(async db => {
    const tables = [offline_tables.all_normal_invoices, offline_tables.all_accounting_invoices];
    let all = [];
    for (const table1 of db.tables) {
      if (tables.includes(table1.name)) {
        let invoices;
        invoices = await table1.filter(invoice => {
          return invoice.userUid === AUTH.currentUser.uid && invoice.buyerTin &&
            (invoice.invoiceNumber.includes(searchParam) && invoice.buyerTin.includes(searchBuyer));
        }).toArray();
        all = [...all, ...invoices];
      }
    }

    return all;
  });
}

export async function getInvoiceForCompensation(invoiceNumber) {

  return offlineDB.open().then(async db => {
    const tables = [offline_tables.all_accounting_invoices, offline_tables.all_normal_invoices];
    for (const table1 of db.tables) {
      if (tables.includes(table1.name)) {
        let invoice = await table1.where("invoiceNumber").equals(`${invoiceNumber}`).first();
        if (invoice) {
          return invoice;
        }
      }
    }
    return undefined;
  });
}

export async function getAllOfflineInvoiceForReportByItemNameOrGroupName(location, itemName, groupName) {
  return offlineDB.open().then(async db => {
    const tables = [offline_tables.all_refunded_invoices, offline_tables.all_normal_invoices];
    let all = [];
    for (const table1 of db.tables) {
      if (tables.includes(table1.name)) {
        let invoices;
        if (location) {
          invoices = await table1.filter(invoice => {
            return invoice.userUid === AUTH.currentUser.uid &&
              invoice.invoiceNumber.startsWith(location) &&
              invoice.items.some(item => itemName ? item.name.startsWith(itemName) :
                (item.restaurantGroup && item.restaurantGroup.startsWith(groupName)));
          }).toArray();
        } else {
          invoices = await table1.filter(invoice => {
            return invoice.userUid === AUTH.currentUser.uid &&
              invoice.items.some(item => item.name.startsWith(itemName));
          }).toArray();
        }
        all = [...all, ...invoices];
      }
    }
    return all;
  });
}

export const getChargeFreeInvoiceFromOfflineDB = async (dateFrom, dateTo, location) => {
  return offlineDB.open().then(async db => {
    const tables = [offline_tables.all_refunded_invoices, offline_tables.all_normal_invoices];
    let all = [];
    for (const table1 of db.tables) {
      if (tables.includes(table1.name)) {
        let invoices;
        if (location) {
          invoices = await table1.where("sdcDateTime")
            .between(moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss"),
              moment(dateTo).format("YYYY-MM-DDTHH:mm:ss"))
            .filter(item => {
              return item.userUid === AUTH.currentUser.uid &&
                item.invoiceNumber.startsWith(location) &&
                item?.internalData?.chargeFree;
            }).toArray();
        } else {
          invoices = await table1.where("sdcDateTime")
            .between(moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss"),
              moment(dateTo).format("YYYY-MM-DDTHH:mm:ss"))
            .filter(item => {
              return item.userUid === AUTH.currentUser.uid &&
                item?.internalData?.chargeFree;
            }).toArray();

        }
        all = [...all, ...invoices];
      }
    }
    return all;
  });
};

// ------------ ERROR & LOGS ------------
export function storeErrorToOffline(method_name, file_name, params = [], error) {
  try {
    let stringParams = "";
    params.forEach(value => {
      stringParams += JSON.stringify(value);
    });
    putLogToFirebase(method_name, file_name, stringParams, error);
  } catch (e) {
  }
}

export function putLogToFirebase(method_name, file_name, params, error) {
  const browser = get_browser();
  console.debug("-----------  putLogToFirebase  --------------");
  console.debug("method_name", method_name);
  console.debug("file_name", file_name);
  console.debug("params", params);
  console.debug("error", error);
  console.debug("browser", browser);
  console.debug("-------------------------");

  const obj = {
    cashier: getSessionOperator().username,
    application: "webESIR",
    version: getSessionAppVersion(),
    params: params || null,
    fileName: file_name || null,
    methodName: method_name || null,
    error: error || null,
    browser: browser || null
  };
  const colref = doc(DB, "log", "users", AUTH.currentUser.uid, Date.now().toString());
  setDoc(colref, obj).then(_ => {
    console.debug("Uspesno sacuvan LOG na firestoru");
  }).catch(_ => {
    try {
      return offlineDB.open().then(db => {
        const table = db.table("all_errors");
        if (table) {
          return table.add({
            method_name: method_name,
            file_name: file_name,
            params: params,
            error: error
          }, {});
        }
      });
    } catch (e) {

    }
  });
}
