import { translate } from "../locales/i18n";
import { getSessionSelectedLocation } from "../helper/session";
import { PATH_DASHBOARD } from "../routes/path";

const ESIR_NUMBER = "426/1.0";
const ESIR_NUMBER_BA = "19/1.1";

export const getEsirNumber = () => {
  const location = getSessionSelectedLocation();
  if (location?.tapURL === "tap.sandbox.suf.poreskaupravars.org") {
    return ESIR_NUMBER_BA;
  }
  return ESIR_NUMBER;
};

export const HIDDEN_PAGES_BH = [
  PATH_DASHBOARD.invoices.advance,
  PATH_DASHBOARD.invoices.old,
  PATH_DASHBOARD.eFacture.analytics,
  PATH_DASHBOARD.eFacture.root,
  PATH_DASHBOARD.reports.writeOff,
  PATH_DASHBOARD.reports.costs,
  PATH_DASHBOARD.reports.turnoverWithoutCompensation,
  PATH_DASHBOARD.reports.representations,
  PATH_DASHBOARD.reports.profit,
  PATH_DASHBOARD.discounts.root,
  PATH_DASHBOARD.general.csvInvoice
];
export const INVOICE_TYPE = {
  normal: "Normal", //PROMET
  proforma: "Proforma", //PREDRAČUN
  copy: "Copy", //KOPIJA
  training: "Training", //OBUKA
  advance: "Advance" //AVANS
};
export const INVOICE_TYPE_ARRAY = Object.values(INVOICE_TYPE);

export const TRANSACTION_TYPE = {
  sale: "Sale", //PRODAJA
  refund: "Refund" //REFUNDACIJA
};
export const TRANSACTION_TYPE_ARRAY = Object.values(TRANSACTION_TYPE);

export const PAYMENT_TYPE = {
  other: "Other", //DRUGO BEZGOTOVINSKO PLACANJE
  cash: "Cash", // GOTOVINA
  card: "Card", //PLATNA KARTICA
  check: "Check", //ČEK
  wireTransfer: "WireTransfer", //PRENOS NA RAČUN
  voucher: "Voucher", // VAUČER
  mobileMoney: "MobileMoney" // INSTANT PLAĆANJE
};

export const PAYMENT_TYPE_ARRAY = Object.values(PAYMENT_TYPE);
export const ALL_LICENSE = {
  webESIR: 0,
  webLPFR: 1,
  eFacture: 2,
  restaurant: 3
};

export const ONE_DAY_SECONDS_TIMEOUT = 86400000;
export const ONE_MINUTE_SECONDS_TIMEOUT = 60000;

export const CHANGE_ITEM_QUANTITY = {
  reduce: 0,
  sum: 1
};
export const INV_TYPES_FOR_REDUCE_QUANTITY = [INVOICE_TYPE.normal];
export const ITEM_UNIT_ONLY_STRINGS = translate("units", { returnObjects: true });

export const RESTAURANT_GROUPS = [
  { id: 0, name: translate("kitchen") },
  { id: 1, name: translate("bar") },
  { id: 2, name: translate("services") },
  { id: 3, name: translate("goods") },
  { id: 4, name: translate("other") }
];

export const RESTAURANT_ITEMS_CATEGORY = [
  { id: 0, name: translate("food") },
  { id: 1, name: translate("beverage") },
  { id: 2, name: translate("other") }
];

export const OPERATORS_GROUP = {
  admin: 0,
  manager: 1,
  operator: 2,
  accountant: 3,
  driver: 4,
  parking: 5,
  adminTourist: 6,
  operatorTourist: 7,
  eFactureAdmin: 31,
  eFactureExitDocuments: 32,
  eFactureEnterDocuments: 33,
  eFactureUser: 34,
  eFactureRevisor: 35
};

export const EFACTURE_SUMMARY_ROLES = [OPERATORS_GROUP.admin, OPERATORS_GROUP.manager, OPERATORS_GROUP.eFactureAdmin,
  OPERATORS_GROUP.eFactureUser];
export const ESIR_OPERATORS_GROUPS = Object.values(OPERATORS_GROUP);
export const REPORTS_ROLES = [OPERATORS_GROUP.admin, OPERATORS_GROUP.manager,
  OPERATORS_GROUP.accountant, OPERATORS_GROUP.adminTourist];

export const CUSTOMER_TYPES = () => [
  {
    value: 10,
    description: "Domaće pravno lice identifikovano PIB-om",
    required: ["tin"],
    message: "Unesite PIB"
  },
  {
    value: 11,
    description: "Domaće fizičko lice koje obavlja samostalnu delatnost identifikovano JMBG-om",
    required: ["jmbg"],
    message: "Unesite JMBG"
  },
  {
    value: 12,
    description: "Domaće pravno lice koje je identifikovano PIB-om i JBKJS-om",
    required: ["tin", "jbkjc"],
    message: "Unesite PIB:JBKJS"
  },
  {
    value: 13,
    description: "Fizičko lice - imalac penzionerske kartice",
    required: ["otherIndificationNumber"],
    message: "Unesite broj kartice"
  },
  {
    value: 16,
    description: "Fizičko lice identifikovano jedinstvenim brojem poljoprivrednog gazdinstva (BPG)",
    required: ["otherIndificationNumber"],
    message: "Unesite BPG"
  },
  {
    value: 20,
    description: "Domaće fizičko lice identifikovano ličnom kartom",
    required: ["otherIndificationNumber"],
    message: "Unesite broj lične karte"
  },
  {
    value: 21,
    description: "Domaće fizičko lice identifikovano izbegličkom legitimacijom",
    required: ["otherIndificationNumber"],
    message: "Unesite broj izbegličke legitimacije"
  },
  {
    value: 22,
    description: "Strano fizičko lice koje ima prijavljeni boravak u Srbiji - EBS",
    required: ["otherIndificationNumber"],
    message: "Unesite ebs"
  },
  {
    value: 23,
    description: "Domaće fizičko lice identifikovano pasošem",
    required: ["otherIndificationNumber"],
    message: "Unesite broj pasoša"
  },
  {
    value: 30,
    description: "Strano fizičko lice koje se identifikuje pasošem",
    required: ["otherIndificationNumber"],
    message: "Unesite broj pasoša"
  },
  {
    value: 31,
    description: "Strano fizičko lice - diplomata",
    required: ["otherIndificationNumber"],
    message: "Broj diplomatkse legitimacije/LK"
  },
  {
    value: 32,
    description: "Strano fizičko lice - koje se identifikuje ličnom kartom iz Makedonije",
    required: ["otherIndificationNumber"],
    message: "Unesite broj lične karte"
  },
  {
    value: 33,
    description: "Strano fizičko lice lice - koje se identifikuje ličnom kartom iz Crne Gore",
    required: ["otherIndificationNumber"],
    message: "Unesite broj lične karte"
  },
  {
    value: 34,
    description: "Strano fizičko lice lice - koje se identifikuje ličnom kartom iz Albanije",
    required: ["otherIndificationNumber"],
    message: "Unesite broj lične karte"
  },
  {
    value: 35,
    description: "Strano fizičko lice lice - koje se identifikuje ličnom kartom iz Bosne i Hercegovine",
    required: ["otherIndificationNumber"],
    message: "Unesite broj lične karte"
  },
  {
    value: 36,
    description: "Strano fizičko lice lice - koje se identifikuje ličnom kartom iz zemalja članice Evropske unije," +
      "Švajcarske konfederacije, Kraljevine Norveške i Republike Island",
    required: ["otherIndificationNumber"],
    message: "Unesite broj lične karte po Odluci"
  },
  {
    value: 40,
    description: "Poreski identifikacioni broj izdat u inostranstvu",
    required: ["tin"],
    message: "Unesite PIB"
  }
];
export const OTHER_INDETIFICATIONS = CUSTOMER_TYPES().filter(item => item.required.includes("otherIndificationNumber"));

export const DISABLE_TIN = CUSTOMER_TYPES().filter(item => !item.required.includes("tin"));
export const ONLY_TIN = CUSTOMER_TYPES().filter(item => item.required.includes("tin"));
export const STATUS_OPTIONS = (translate) => [
  { value: "SVI", label: translate("all") },
  { value: "AKTIVAN", label: translate("active") },
  { value: "PASIVAN", label: translate("inactive") }
];

export const SETTINGS_PAYMENTS = (translate) => [
  {
    name: "cash",
    value: PAYMENT_TYPE.cash,
    icon: "mdi:cash",
    displayName: translate("cash"),
    index: 0,
    valueIndex: "1"
  },
  {
    name: "card",
    value: PAYMENT_TYPE.card,
    icon: "icon-park-solid:bank-transfer",
    displayName: translate("card"),
    index: 2,
    valueIndex: "2"
  },
  {
    name: "check",
    value: PAYMENT_TYPE.check,
    icon: "material-symbols:fact-check-outline",
    displayName: translate("check"),
    index: 3,
    valueIndex: "3"
  },
  {
    name: "mobile",
    value: PAYMENT_TYPE.mobileMoney,
    icon: "mdi:mobile-phone-sound",
    displayName: translate("mobileMoney"),
    index: 1,
    valueIndex: "6"
  },
  {
    name: "voucher",
    value: PAYMENT_TYPE.voucher,
    icon: "mdi:voucher",
    displayName: translate("voucher"),
    index: 5,
    valueIndex: "5"
  },
  {
    name: "virman",
    value: PAYMENT_TYPE.wireTransfer,
    icon: "mdi:credit-card-wireless-outline",
    displayName: translate("wireTransfer"),
    index: 4,
    valueIndex: "4"
  },
  {
    name: "other",
    value: PAYMENT_TYPE.other,
    icon: "basil:other-1-outline",
    displayName: translate("otherPaymentType") ,
    index: 6,
    valueIndex: "0"
  }
];

export const INVOICE_TYPE_OPTIONS = (translate) => [
  {
    value: INVOICE_TYPE.normal,
    title: translate("trafficSale"),
    description: "Lorem impsum."
  },
  {
    value: INVOICE_TYPE.advance,
    title: translate("advance"),
    description: "Lorem impsum."
  },
  {
    value: INVOICE_TYPE.proforma,
    title: translate("proforma"),
    description: "Lorem impsum."
  }
];


export const PAYMENT_EXTRA_OPTIONS = [
  {
    value: "promet_bez_naknade",
    title: translate("nonFeeTransactions"),
    description: "Lorem impsum."
  }
  // {
  //   value: "reprezentacija",
  //   title: translate("representations"),
  //   description: "Lorem impsum."
  // }
];

export const ESCAPE_CHARACTERS_FOR_SEARCH = ["Shift", "Backspace", "Enter", "Tab", "Meta", "CapsLock", "Control",
  "Alt", "NumLock", "PageUp", "PageDown", "End", "F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9", "F10", "F11",
  "F12", "PrintScreen", "Home", "Pause", "Insert", "ScrollLock", "PrintScreen", "ArrowUp", "ArrowDown", "ArrowLeft",
  "ArrowRight"];
