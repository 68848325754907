// DENI
Array.prototype.move = function(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};

export const isEmpty = (value) => {
  return value === undefined || value === null || value === "";
};
export const getFileExtension = (filename) => {
  return filename.split(".").pop();
};
export const formatQuantity = (qty) => {
  if (qty === undefined || qty === null) {
    return 0;
  }
  const qtyFormat = qty;
  if (typeof qty !== "number") {
    parseFloat((qtyFormat));
  }
  const checkDecimals = qty.toString();
  if (checkDecimals.includes(".") && checkDecimals.split(".")[1].length > 3) {
    return parseFloat(parseFloat(qtyFormat).toFixed(3));
  } else {
    return parseFloat((qtyFormat));
  }
};
export const customSupplierFilter = (options, state) => {
  return options.filter(item =>
    (item.TIN && item.TIN.includes(state.inputValue)) ||
    (item.name && item.name.includes(state.inputValue)) ||
    (item.CIN && item.CIN.includes(state.inputValue))
  );
};

export function getAllCurrentTaxRates(rates) {
  const allRates = [];
  for (const rate of rates) {
    for (const taxRAte of rate.taxRates) {
      allRates.push(taxRAte);
    }
  }
  return allRates;
}

export function blobToImage(image) {
  if (image) {
    return URL.createObjectURL(image);
  }
  return undefined;
}

export const isImage16by9 = async (file) =>
  await new Promise((resolve) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const aspectRatio = img.width / img.height;
          if (Math.abs(aspectRatio - (16 / 9)) < 0.01) {
            resolve(true);
          } else {
            resolve(false);
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      resolve(false);
    }
  });

export const printHTML = async (html, printA4 = false) => {
  let w = window.open(`?a4=${printA4}`, "", "");
  await new Promise(resolve => {
    w.addEventListener("load", resolve);
    w.addEventListener("readystatechange", resolve);
    w.addEventListener("afterprint", (event) => {
      console.log("After print", event);
    });
  });
  w.document.write(html);
  setTimeout(_ => {
    w.window.print(null);
      w.close();
  }, 200);
};
