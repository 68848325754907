import {useState} from "react";
import {StyledCard} from "../../../components/settings/styles";
import Iconify from "../../../components/Iconify";
import {useLocales} from "../../../locales";

export default function FullScreenOptions({showText = true}) {
  const { translate } = useLocales();
  const [fullscreen, setFullscreen] = useState(false);

  const onToggleFullScreen = async () => {
    if (!document.fullscreenElement) {
      await document.documentElement.requestFullscreen();
      setFullscreen(true);
    } else if (document.exitFullscreen) {
      await document.exitFullscreen();
      setFullscreen(false);
    }
  };

  return (
    <StyledCard
      selected={fullscreen}
      onClick={onToggleFullScreen}
      sx={{
          height: !showText ? 35 : 48,
          width: !showText ? 35 : "auto",
          border: !showText ? "none" : "auto",
        typography: "subtitle2",
        "& .svg-color": {
          ml: 1,
          width: 16,
          height: 16
        }
      }}>
        {showText && translate(fullscreen ? "minimize" : "fullScreen")}

      <Iconify
        icon={`${fullscreen ? "mdi:fullscreen-exit" : "ant-design:fullscreen-outlined"}`}
      />
    </StyledCard>
  );
}
