import {createSlice, current} from "@reduxjs/toolkit";

const initialState = {
  cart: [],
  discount: undefined,
  savedInvoice: undefined,
  customer: undefined,
  selectedItem: -1
};
export const slice = createSlice({
  name: "createInvoice",
  initialState,
  reducers: {
    addQuickDiscount: (state, { payload }) => {
      const arr = [...current(state.cart)];
      const index = arr.findIndex(item => item.uid === payload.uid);
      if (index !== -1) {
        arr[index] = {
          ...arr[index],
          discountedPrice: payload.discountedPrice
        };
        state.cart = arr;
      }
    },
    setCustomer: (state, { payload }) => {
      state.customer = payload;
    },
    removeItemFromCart: (state, { payload }) => {
      const arr = [...current(state.cart)];
      let index = arr.findIndex(lItem => lItem.uid === payload.uid);
      if (payload === "last") {
        index = state.selectedItem;
      }
        if (payload === "first") {
            index = 0
        }
      arr.splice(index, 1);
      state.cart = arr;
    },
    changeSelectedItem: (state) => {
      const arr = [...current(state.cart)];
      const selectedItem = state.selectedItem;
      if (arr.length > 0) {
        if (arr.length - 1 > selectedItem) {
          state.selectedItem = selectedItem + 1;
        } else {
          state.selectedItem = 0;
        }
      }
    },
    setSavedInvoice: (state, { payload }) => {
      if (payload) {
        state.cart = payload.items;
        state.discount = payload.discount;
      }
      state.savedInvoice = payload;
    },
    resetCart: (state) => {
      state.cart = [];
      state.discount = undefined;
      state.customer = undefined;
      state.savedInvoice = undefined;
      state.selectedItem = -1;
    },
    setDiscountCart: (state, { payload }) => {
      let arr = [...current(state.cart)];
      if (payload) {
        arr = arr.map(item => {
          if ((payload.productsUids || []).includes(item.uid)) {
            const discount = item.price * (payload.percentage / 100);
            const discountedPrice = item.price - discount;
            return {
              ...item,
              discountedPrice: discountedPrice
            };
          }
          return item;
        });
      } else {
        arr = arr.map(item => ({
          ...item,
          discountedPrice: undefined
        }));
      }
      state.cart = arr;
      state.discount = payload;
    },
    changeProductOnCart: (state, { payload }) => {
      const { uid, name, price } = payload;
      const arr = [...current(state.cart)];
      const index = arr.findIndex(lItem => lItem.uid === uid);
      if (index !== -1) {
        const discountState = state.discount;
        let discountedPrice;
        if (discountState) {
          if (discountState.productsUids.includes(uid)) {
            const discount = (price || arr[index].price) * (discountState.percentage / 100);
            discountedPrice = (price || arr[index].price) - discount;
          }
        }
        arr[index] = {
          ...arr[index],
          discountedPrice,
          price: price || arr[index].price,
          name: name || arr[index].name
        };
      }
      state.cart = arr;
    },
    setCartItemQuantity: (state, { payload }) => {
      const { item, quantity } = payload;
      const arr = [...current(state.cart)];
      const index = arr.findIndex(lItem => lItem.uid === item.uid);
      if (quantity > 0) {
        const discountState = state.discount;
        let discountedPrice;
        if (discountState) {
          if (discountState.productsUids.includes(item.uid)) {
            const discount = item.price * (discountState.percentage / 100);
            discountedPrice = item.price - discount;
          }
        }
        if (index !== -1) {
          arr[index] = {
            ...arr[index],
            addedQuantity: quantity,
            discountedPrice
          };
        } else {
          arr.push({ ...item, unitPrice: item.price, addedQuantity: quantity, discountedPrice });
        }
      } else {
        arr.splice(index, 1);
      }
      state.cart = arr;
    },
    addItemToCart: (state, { payload }) => {
      const { item, quantity } = payload;
      if (quantity <= 0) {
        return;
      }
      const arr = [...current(state.cart)];
      let index = arr.findIndex(lItem => lItem.uid === item.uid);
      if (item === "last") {
        index = state.selectedItem;
        if (arr.length === 0 || index === -1) {
          return;
        }
      }
      const discountState = state.discount;
      let discountedPrice;
      if (discountState) {
        if (discountState.productsUids.includes(item.uid)) {
          const discount = item.price * (discountState.percentage / 100);
          discountedPrice = Number(Number(item.price - discount).toFixed(2));
        }
      }
      if (index !== -1) {
        arr[index] = {
          ...arr[index],
          addedQuantity: Number(Number(arr[index].addedQuantity + quantity).toFixed(4)),
          discountedPrice
        };
      } else {
        arr.unshift({ ...item, unitPrice: item.price, addedQuantity: Number(quantity), discountedPrice });
      }
      state.cart = arr;
    },
    reduceItemFromCart: (state, { payload }) => {
      const { item, quantity } = payload;
      const arr = [...current(state.cart)];
      let index = arr.findIndex(lItem => lItem.uid === item.uid);
      if (item === "last") {
        index = state.selectedItem;
      }
      if (arr.length === 0) {
        index = -1;
      }
      if (index !== -1) {
        const newQty = arr[index].addedQuantity - (quantity || 0);
        if (newQty > 0) {
          arr[index] = {
            ...arr[index],
            addedQuantity: Number(Number(newQty).toFixed(4))
          };
        } else {
          arr.splice(index, 1);
          state.selectedItem = -1;
        }
      }
      state.cart = arr;
    }
  }
});

export const {
  removeItemFromCart,
  addItemToCart,
  changeSelectedItem,
  reduceItemFromCart,
  resetCart,
  setDiscountCart,
  setSavedInvoice,
  setCartItemQuantity,
  changeProductOnCart,
  setCustomer,
  addQuickDiscount
} = slice.actions;
export default slice.reducer;

